import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

function ButtonTooltip({ renderItem, text }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{renderItem()}</TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default ButtonTooltip;
