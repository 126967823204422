import { useState, useRef, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { useTranslation } from "react-i18next";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Checkbox } from "./ui/checkbox";
import { Button } from "./ui/button";
import LoggerContext from "../context/LoggerProvider";
import useOutsideClick from "../hooks/useOutsideClick";

import Interpolation from "./Interpolation";

import "./Item.css";
import Tiptap from "./richtext/TipTap";
import { TiptapProvider } from "../context/TipTapProvider";
import AutogrowTextarea from "./ui/autogrowtextarea";
import SortableItem from "./SortableItem";

const EDITABLE = {
  checkbox: true,
  orderedlist: true,
  slider: true,
  textbox: true,
  textarea: true,
  gauge: true,
  timer: true,
};

function Item({
  item,
  index,
  removeItemFromList,
  setShowItemEdit,
  setItemIdToEdit,
  setTempOptions,
  setTempItems,
  saveItemContent,
  saveOptionItemContent,
  saveItemItemContent,
  autofocus,
  contentRefs,
  tempFlowContext,
  onUpdate,
  onCleanup,
}) {
  const [itemLabelEditMode, setItemLabelEditMode] = useState(!!autofocus);
  const [itemOptionLabelEditMode, setItemOptionLabelEditMode] = useState(-1);
  const [itemItemLabelEditMode, setItemItemLabelEditMode] = useState(-1);
  const [staticValue] = useState("");
  const [labelValue, setLabelValue] = useState(
    typeof item.content === "string" ? item.content : (item.content.label ?? "")
  );
  const [optionLabelValue, setOptionLabelValue] = useState("");
  const [itemLabelValue, setItemLabelValue] = useState("");

  const labelInputRef = useRef(null);
  const optionLabelInputRef = useRef(null);
  const itemLabelInputRef = useRef(null);
  const optionIdxRef = useRef(-1);
  const itemIdxRef = useRef(-1);
  const indexRef = useRef(-1);

  const { Logger } = useContext(LoggerContext);

  const { t } = useTranslation();

  const handleItemLabelChange = (e) => {
    setLabelValue(e.target.value);
  };

  const handleOptionItemLabelChange = (e) => {
    setOptionLabelValue(e.target.value);
  };

  const handleItemItemLabelChange = (e) => {
    setItemLabelValue(e.target.value);
  };

  const handleSave = () => {
    Logger.debug()(
      "Saving item label",
      index,
      indexRef.current,
      labelInputRef.current.value
    );
    setItemLabelEditMode(false);
    saveItemContent(indexRef.current, labelInputRef.current.value);
  };

  const handleSaveOption = () => {
    saveOptionItemContent(
      index,
      optionLabelInputRef.current.value,
      optionIdxRef.current
    );
    setItemOptionLabelEditMode(-1);
    setOptionLabelValue("");
  };

  const handleSaveItem = () => {
    saveItemItemContent(
      index,
      itemLabelInputRef.current.value,
      itemIdxRef.current
    );
    setItemItemLabelEditMode(-1);
    setItemLabelValue("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      setItemLabelEditMode(false);
      setLabelValue(
        typeof item.content === "string"
          ? item.content
          : (item.content.label ?? "")
      );
    }
    e.stopPropagation();
  };

  const handleOptionKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveOption();
    } else if (e.key === "Escape") {
      setItemOptionLabelEditMode(-1);
      setOptionLabelValue("");
    }
  };

  const handleItemKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSaveItem();
    } else if (e.key === "Escape") {
      setItemItemLabelEditMode(-1);
      setItemLabelValue("");
    }
  };

  const renderComponent = () => {
    switch (item.type) {
      case "interpolation":
        return (
          <Interpolation
            value={item.content}
            contentRefs={contentRefs}
            itemId={item.id}
            flowcontext={tempFlowContext}
            onUpdate={onUpdate}
            autofocus={autofocus}
          />
        );

      case "richtext":
        return <div dangerouslySetInnerHTML={{ __html: item.content }}></div>;
      case "hr":
        return <hr />;
      case "headline1":
        return (
          <h2
            className="text-2xl text-bold"
            onClick={() => setItemLabelEditMode(true)}
          >
            {itemLabelEditMode ? (
              <AutogrowTextarea
                minHeight={5}
                inlinemode
                ref={labelInputRef}
                value={labelValue}
                onChange={handleItemLabelChange}
                onKeyDown={handleKeyDown}
                autoFocus={autofocus}
              />
            ) : (
              labelValue
            )}
          </h2>
        );
      case "headline2":
        return (
          <h3
            className="text-lg leading-tight text-start"
            onClick={() => setItemLabelEditMode(true)}
          >
            {itemLabelEditMode ? (
              <AutogrowTextarea
                minHeight={5}
                inlinemode
                ref={labelInputRef}
                value={labelValue}
                onChange={handleItemLabelChange}
                onKeyDown={handleKeyDown}
                autoFocus={autofocus}
              />
            ) : (
              labelValue
            )}
          </h3>
        );
      case "paragraph":
        return (
          <p onClick={() => setItemLabelEditMode(true)}>
            {itemLabelEditMode ? (
              <AutogrowTextarea
                minHeight={5}
                inlinemode
                ref={labelInputRef}
                value={labelValue}
                onChange={handleItemLabelChange}
                onKeyDown={handleKeyDown}
                autoFocus={autofocus}
              />
            ) : (
              labelValue
            )}
          </p>
        );
      case "textbox":
        return (
          <div>
            <Label
              className="font-semibold"
              onClick={() => setItemLabelEditMode(true)}
            >
              {itemLabelEditMode ? (
                <input
                  ref={labelInputRef}
                  className="editableInput"
                  value={labelValue}
                  onChange={handleItemLabelChange}
                  onKeyDown={handleKeyDown}
                  autoFocus={autofocus}
                />
              ) : (
                labelValue
              )}
            </Label>
            <Input type="text" className="max-w-xl mb-3" value={staticValue} />
          </div>
        );
      case "textarea":
        return (
          <div>
            <Label
              className="font-semibold"
              onClick={() => setItemLabelEditMode(true)}
            >
              {itemLabelEditMode ? (
                <input
                  ref={labelInputRef}
                  className="editableInput"
                  value={labelValue}
                  onChange={handleItemLabelChange}
                  onKeyDown={handleKeyDown}
                  autoFocus={autofocus}
                />
              ) : (
                labelValue
              )}
            </Label>
            <textarea
              className="block mt-2 p-2.5 w-full text-sm rounded-lg border focus:border-blue-500 bg-secondary border-gray-600 placeholder-gray-400 text-black focus:ring-blue- outline-none"
              rows="5"
              cols="50"
              value={staticValue}
            />
          </div>
        );
      case "checkbox":
        return (
          <div className="text-start">
            <Label
              className="font-semibold"
              onClick={() => {
                setItemLabelEditMode(true);
                setItemOptionLabelEditMode(-1);
              }}
            >
              {itemLabelEditMode ? (
                <input
                  ref={labelInputRef}
                  className="editableInput"
                  value={labelValue}
                  onChange={handleItemLabelChange}
                  onKeyDown={handleKeyDown}
                  autoFocus={autofocus}
                />
              ) : (
                labelValue
              )}
            </Label>
            {item.content.options.map((option, optIdx) => (
              <div key={option.key} className="mt-2 flex items-center">
                <Checkbox checked={staticValue} />
                <Label
                  className="text-base font-normal mx-1.5"
                  onClick={() => {
                    if (itemOptionLabelEditMode === -1) {
                      setOptionLabelValue(option.label);
                      setItemLabelEditMode(false);
                      setItemOptionLabelEditMode(optIdx);
                    }
                  }}
                >
                  {itemOptionLabelEditMode === optIdx ? (
                    <input
                      ref={optionLabelInputRef}
                      className="editableInput"
                      value={optionLabelValue}
                      onChange={handleOptionItemLabelChange}
                      onKeyDown={handleOptionKeyDown}
                    />
                  ) : (
                    option.label
                  )}
                </Label>
              </div>
            ))}
          </div>
        );
      case "orderedlist":
        return (
          <div className="optioncomponent ptop">
            <div className="labeltoitems">
              <b onClick={() => setItemLabelEditMode(true)}>
                {itemLabelEditMode ? (
                  <input
                    ref={labelInputRef}
                    className="editableInput"
                    value={labelValue}
                    onChange={handleItemLabelChange}
                    onKeyDown={handleKeyDown}
                    autoFocus={autofocus}
                  />
                ) : (
                  labelValue
                )}
              </b>
            </div>
            <ol className="itemol">
              {item.content.items.map((olitem, liIdx) => (
                <li
                  onClick={() => {
                    if (itemItemLabelEditMode === -1) {
                      setItemLabelValue(olitem.label);
                      setItemLabelEditMode(false);
                      setItemItemLabelEditMode(liIdx);
                    }
                  }}
                >
                  {" "}
                  {itemItemLabelEditMode === liIdx ? (
                    <input
                      ref={itemLabelInputRef}
                      className="editableInput"
                      value={itemLabelValue}
                      onChange={handleItemItemLabelChange}
                      onKeyDown={handleItemKeyDown}
                    />
                  ) : (
                    olitem.label
                  )}
                </li>
              ))}
            </ol>
          </div>
        );
      case "gauge":
        return (
          <div className="gaugeflex">
            <div className="gaugecanvasitem">
              <canvas
                width="100"
                height="100"
                style={{ backgroundColor: "#eeeeee", borderRadius: "5px" }}
              />
            </div>
            <div className="gaugefeedbackitem">
              <textarea
                rows="3"
                cols="50"
                className="block mt-2 px-2.5 py-1.5 w-full text-sm rounded-lg border focus:border-blue-500 bg-secondary border-gray-600 placeholder-gray-400 text-black focus:ring-blue outline-none"
                value={staticValue}
              />
            </div>
          </div>
        );
      case "timer":
        return (
          <div>
            <div className="timersize">00:00</div>
            <Button className="me-2 bg-black hover:bg-slate-900">
              {t("start_stop")}
            </Button>
            <Button variant="outline">{t("reset")}</Button>
          </div>
        );
      case "slider":
        return (
          <div className="text-start">
            <Label
              className="font-semibold block mb-2"
              onClick={() => setItemLabelEditMode(true)}
            >
              {itemLabelEditMode ? (
                <input
                  ref={labelInputRef}
                  className="editableInput"
                  value={labelValue}
                  onChange={handleItemLabelChange}
                  onKeyDown={handleKeyDown}
                  autoFocus={autofocus}
                />
              ) : (
                labelValue
              )}
            </Label>
            <div className="flex items-center">
              <input
                type="range"
                className="w-[400px]"
                min={item.content.min}
                max={item.content.max}
                step={item.content.step}
                value={staticValue}
              />
            </div>
          </div>
        );
      case "richtextnew":
        return (
          <TiptapProvider>
            <Tiptap
              onUpdate={(value) => saveItemContent(indexRef.current, value)}
              value={item.content}
            />
          </TiptapProvider>
        );
      default:
    }
  };

  useOutsideClick(labelInputRef, handleSave);
  useOutsideClick(optionLabelInputRef, handleSaveOption);
  useOutsideClick(itemLabelInputRef, handleSaveItem);

  useEffect(() => {
    setLabelValue(
      typeof item.content === "string"
        ? item.content
        : (item.content.label ?? "")
    );
  }, [item.content]);

  useEffect(() => {
    if (autofocus) {
      if (labelInputRef.current) {
        labelInputRef.current.select();
      }
    }
  }, [autofocus]);

  useEffect(() => {
    if (itemOptionLabelEditMode !== -1) {
      optionIdxRef.current = itemOptionLabelEditMode;
    }
  }, [itemOptionLabelEditMode]);

  useEffect(() => {
    if (itemItemLabelEditMode !== -1) {
      itemIdxRef.current = itemItemLabelEditMode;
    }
  }, [itemItemLabelEditMode]);

  useEffect(() => {
    Logger.debug()("INDEX changed for item", index, item);
    indexRef.current = index;
  }, [index]);

  return (
    <div className="builderview">
      <div className="builderresult">
        <SortableItem
          className="border border-dashed border-gray-200 px-2 py-2"
          id={item.id}
        >
          {renderComponent()}
        </SortableItem>
      </div>
      <div className="flex flex-col gap-3 items-center px-2 justify-center">
        {item.type === "interpolation" ? (
          <FontAwesomeIcon
            className="h-4 w-4"
            icon={solid("circle-check")}
            onClick={onCleanup}
            title={t("cleanup_component")}
          />
        ) : (
          <></>
        )}
        {item.content && EDITABLE[item.type] ? (
          <FontAwesomeIcon
            className="h-4 w-4"
            icon={solid("edit")}
            onClick={() => {
              setItemIdToEdit(item.id);
              if (item.content.options) {
                setTempOptions(item.content.options);
              }
              if (item.content.items) {
                setTempItems(item.content.items);
              }
              setShowItemEdit(true);
            }}
            title={t("edit_component")}
          />
        ) : (
          <></>
        )}
        <FontAwesomeIcon
          className="h-4 w-4"
          icon={solid("trash")}
          onClick={removeItemFromList}
          title={t("remove_component")}
        />
      </div>
    </div>
  );
}

export default Item;
