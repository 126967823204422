import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import useRetryFetch from "../../hooks/useRetryFetch";
import { useTranslation } from "react-i18next";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

import { ChevronsUpDown } from "lucide-react";

import { Button } from "./button";

function ComboBox({
  defaultValue = "",
  onChange,
  baseURL,
  sortBy = "",
  limit = 20,
  labelProp,
  valueProp,
  searchParam = "q",
  apiProp,
  debounceTimerMs = 800,
  exclude = "",
  setIsComboboxValid,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState(null);
  const [options, setOptions] = useState([]);

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const skipRef = useRef(0);
  const scrollElement = useRef();

  const retryFetch = useRetryFetch();

  const { t } = useTranslation();

  const handleScroll = (e) => {
    const element = e.target;
    if (e.target === scrollElement.current) {
      const isScrolledToBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;

      if (isScrolledToBottom) {
        (async function () {
          // load next bunch if scrolled to bottom
          let response = await retryFetch(
            `${baseURL}/${apiProp}?limit=${normalizedLimit}&skip=${
              skipRef.current
            }${sortBy ? `&sortBy=${sortBy}` : ""}`,
            {
              credentials: "include",
            }
          );
          let data = await response.json();

          skipRef.current = skipRef.current + normalizedLimit;

          setOptions((oldOptions) => {
            let newOptions = structuredClone(oldOptions);

            // only add users that are not already in the list
            data[apiProp] = data[apiProp].filter(
              (apiObj) =>
                !oldOptions.find((option) => option.value === apiObj[valueProp])
            );

            // and that are not the excluded id
            if (exclude) {
              data[apiProp] = data[apiProp].filter(
                (apiObj) => apiObj[valueProp] !== exclude
              );
            }

            newOptions = [
              ...newOptions,
              ...data[apiProp].map((apiObj) => ({
                label: createLabelFromLabelProp(apiObj),
                value: apiObj[valueProp],
                fullObject: apiObj,
              })),
            ];

            return Array.from(new Set(newOptions));
          });
        })();
      }
    }
  };

  const normalizedLimit = useMemo(() => {
    let added = exclude ? 1 : 0;
    if (limit > 0 && limit < 10 + added) return 10 + added;
    return limit;
  }, [limit]);

  const createLabelFromLabelProp = useCallback(
    (option) => {
      let result = labelProp;
      if (!result.includes("{")) {
        // simple
        return option[result];
      }

      // complex interpolation
      let matches = result.match(/\{.*?\}/g);

      for (let theMatch of matches) {
        let theVariable = theMatch.match(/\{(.*)\}/)[1];
        result = result.replace(theMatch, option[theVariable]);
      }

      return result;
    },
    [labelProp]
  );

  useEffect(() => {
    if (
      defaultValue &&
      defaultValue !== exclude &&
      (!selectedValue || !selectedLabel)
    ) {
      (async function () {
        try {
          // load element for defaultValue, so that information can be shown
          let response = await retryFetch(
            `${baseURL}/${apiProp}/${defaultValue}`,
            { credentials: "include" }
          );
          let data = await response.json();
          setSelectedValue(createLabelFromLabelProp(data) ? defaultValue : "");
          setSelectedLabel(createLabelFromLabelProp(data) || "");
          onChange(data);
          setIsComboboxValid(true);
        } catch (e) {
          // no element for defaultValue found!
          setSelectedValue("");
          setSelectedLabel("");
          onChange({});
          setIsComboboxValid(false);
        }
      })();
    }
  }, [defaultValue, selectedValue, selectedLabel]);

  useEffect(() => {
    let timerReference = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, debounceTimerMs);

    return () => {
      clearTimeout(timerReference);
    };
  }, [inputValue]);

  useEffect(() => {
    (async function () {
      // initially load first bunch
      setIsLoading(true);
      let response = await retryFetch(
        `${baseURL}/${apiProp}?limit=${normalizedLimit}&skip=${
          skipRef.current
        }${sortBy ? `&sortBy=${sortBy}` : ""}`,
        { credentials: "include" }
      );
      let data = await response.json();

      // not the excluded id
      if (exclude) {
        data[apiProp] = data[apiProp].filter(
          (apiObj) => apiObj[valueProp] !== exclude
        );
      }

      skipRef.current = skipRef.current + normalizedLimit;

      setOptions(
        data[apiProp].map((apiObj) => ({
          label: createLabelFromLabelProp(apiObj),
          value: apiObj[valueProp],
          fullObject: apiObj,
        }))
      );
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (limit > 0) {
          scrollElement.current = document.querySelector("div[cmdk-list]");

          scrollElement.current.addEventListener("scroll", handleScroll);
          console.log("SCROLL detection added");
        }
      }, 200);
    } else {
      if (scrollElement.current) {
        scrollElement.current.removeEventListener("scroll", handleScroll);
        scrollElement.current = null;
        console.log("SCROLL detection removed");
        setDebouncedInputValue(null);
      }
    }
  }, [open]);

  useEffect(() => {
    if (debouncedInputValue) {
      if (scrollElement.current) {
        scrollElement.current.removeEventListener("scroll", handleScroll);
        scrollElement.current = null;
        console.log("SCROLL detection removed");
      }
      (async function () {
        // search for input value
        let response = await retryFetch(
          `${baseURL}/${apiProp}/search?${searchParam}=${debouncedInputValue}&limit=${normalizedLimit}&skip=0${
            sortBy ? `&sortBy=${sortBy}` : ""
          }`,
          { credentials: "include" }
        );
        let data = await response.json();

        // not the excluded if
        if (exclude) {
          data[apiProp] = data[apiProp].filter(
            (apiObj) => apiObj[valueProp] !== exclude
          );
        }

        setOptions(
          data[apiProp].map((apiObj) => ({
            label: createLabelFromLabelProp(apiObj),
            value: apiObj[valueProp],
            fullObject: apiObj,
          }))
        );
      })();
    } else if (debouncedInputValue === "") {
      // get back to initial load, because search input value has been deleted
      skipRef.current = 0;
      (async function () {
        let response = await retryFetch(
          `${baseURL}/${apiProp}?limit=${normalizedLimit}&skip=${
            skipRef.current
          }${sortBy ? `&sortBy=${sortBy}` : ""}`,
          { credentials: "include" }
        );
        let data = await response.json();

        // not the excluded id
        if (exclude) {
          data[apiProp] = data[apiProp].filter(
            (apiObj) => apiObj[valueProp] !== exclude
          );
        }

        skipRef.current = skipRef.current + normalizedLimit;

        setOptions(
          data[apiProp].map((apiObj) => ({
            label: createLabelFromLabelProp(apiObj),
            value: apiObj[valueProp],
            fullObject: apiObj,
          }))
        );
        if (open) {
          setTimeout(() => {
            if (limit > 0) {
              scrollElement.current = document.querySelector("div[cmdk-list]");

              scrollElement.current.addEventListener("scroll", handleScroll);
              console.log("SCROLL detection added");
            }
          }, 200);
        }
      })();
    }
  }, [debouncedInputValue]);

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="w-[400px] justify-between"
        >
          {selectedLabel ||
            (isLoading
              ? t("loading_options")
              : options.length
              ? t("please_select_an_option")
              : t("no_results_found"))}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput
            value={inputValue}
            onInput={(e) => {
              setInputValue(e.currentTarget.value);
              if (scrollElement.current) {
                scrollElement.current.removeEventListener(
                  "scroll",
                  handleScroll
                );
                scrollElement.current = null;
                console.log("SCROLL detection removed");
              }
            }}
          />
          <CommandList>
            <CommandEmpty>{t("no_results_found")}</CommandEmpty>
            {options.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={() => {
                  setSelectedValue(option.value);
                  setSelectedLabel(option.label);
                  onChange(option.fullObject);
                  setIsComboboxValid(true);
                  setOpen(false);
                }}
              >
                {option.label}
              </CommandItem>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default ComboBox;
