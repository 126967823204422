import { createContext } from "react";
import { config } from "../Environment";

const LoggerContext = createContext({});

export const LoggerProvider = ({ children }) => {
  const loglevel = parseInt(config.LOG_LEVEL);

  const LOG_LEVELS = {
    DEBUG: 0,
    INFO: 1,
    WARN: 2,
    ERROR: 3,
    NONE: 4,
  };

  const Logger = {
    debug:
      loglevel === LOG_LEVELS.DEBUG
        ? function () {
            const timestamp = new Date().toISOString() + ": [DEBUG]";
            return Function.prototype.bind.call(
              console.log,
              console,
              timestamp
            );
          }
        : function () {
            return function () {};
          },
    info:
      loglevel <= LOG_LEVELS.INFO
        ? function () {
            const timestamp = new Date().toISOString() + ": [INFO]";
            return Function.prototype.bind.call(
              console.log,
              console,
              timestamp
            );
          }
        : function () {
            return function () {};
          },
    warn:
      loglevel <= LOG_LEVELS.WARN
        ? function () {
            const timestamp = new Date().toISOString() + ": [WARN]";
            return Function.prototype.bind.call(
              console.log,
              console,
              timestamp
            );
          }
        : function () {
            return function () {};
          },
    error:
      loglevel <= LOG_LEVELS.ERROR
        ? function () {
            const timestamp = new Date().toISOString() + ": [ERROR]";
            return Function.prototype.bind.call(
              console.error,
              console,
              timestamp
            );
          }
        : function () {
            return function () {};
          },
  };

  return (
    <LoggerContext.Provider value={{ loglevel, Logger, LOG_LEVELS }}>
      {children}
    </LoggerContext.Provider>
  );
};

export default LoggerContext;
