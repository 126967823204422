import DesignView from "./DesignView";
import { MyContextProvider } from "./reactflow/MyContextProvider";
import { ReactFlowProvider } from "reactflow";

export default function DesignViewWrapper() {
  return (
    <MyContextProvider>
      <ReactFlowProvider>
        <DesignView />
      </ReactFlowProvider>
    </MyContextProvider>
  );
}
