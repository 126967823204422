import { useNavigate } from "react-router-dom";
import { config } from "../Environment";
import { useEffectOnce } from "../hooks/useEffectOnce";
import useRetryFetch from "../hooks/useRetryFetch";
import { useContext } from "react";
import LoggerContext from "../context/LoggerProvider";
import useAuth from "../hooks/useAuth";

function Home() {
  const navigate = useNavigate();
  const retryFetch = useRetryFetch();

  const { auth } = useAuth();

  const { Logger } = useContext(LoggerContext);

  useEffectOnce(() => {
    if (auth?.email) {
      navigate("/prescriptionlist");
    } else {
      // navigate to "Welcome to ClaryNext" prescription in run mode

      const startWelcome = async () => {
        // get welcome prescription
        let welcomeResponse = await fetch(config.API_BASE + `/api/welcome`);
        let welcome = await welcomeResponse.json();

        // create a new process and start it
        let newResponse = await retryFetch(
          config.API_BASE + `/api/processes/${welcome._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              email: auth.email,
            }),
          }
        );
        let result = await newResponse.json();
        Logger.debug()(result);

        sessionStorage.setItem("prescriptionID", welcome._id);
        sessionStorage.setItem(
          "prescriptionHistoryID",
          result.prescriptionHistoryID
        );
        sessionStorage.setItem("runProcessID", result.processId);
        sessionStorage.removeItem("nextActivity");
        sessionStorage.setItem("isWelcome", "true");
        navigate("/preview");
      };

      startWelcome();
    }
  }, []);

  return <div className="center">Loading Welcome Prescription...</div>;
}

export default Home;
