import useRefreshToken from "./useRefreshToken";
import { useContext } from "react";
import LoggerContext from "../context/LoggerProvider";
import { WSContext } from "../App";

const delay = (millis) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, millis);
  });
};

const useRetryFetch = () => {
  const refresh = useRefreshToken();

  const { Logger } = useContext(LoggerContext);
  const { globalAbortController, setGlobalAbortController } =
    useContext(WSContext);

  const accessToken = () => {
    return localStorage.getItem("accessToken");
  };

  const semaphore = () => {
    return !!localStorage.getItem("email") &&
      sessionStorage.getItem("semaphore") === "true"
      ? true
      : false;
  };

  const setSemaphore = (newSemaValue) => {
    sessionStorage.setItem("semaphore", newSemaValue);
  };

  return async (url, options) => {
    // no cache!
    let querypart = "?";
    if (url.includes("?")) querypart = "&";

    url += querypart + "t=" + Date.now();

    // is AbortController still usable? If not, create new instance
    let locAbortController = globalAbortController;
    if (locAbortController.signal.aborted) {
      locAbortController = new AbortController();
      setGlobalAbortController(locAbortController);
    }

    Logger.info()("useRetryFetch started", url);
    if (semaphore()) {
      Logger.debug()("useRetryFetch has to wait", url);
      await new Promise((resolve, reject) => {
        let interval = window.setInterval(() => {
          if (locAbortController.signal.aborted) {
            Logger.debug()("useRetryFetch ABORT detected, stopping wait cycle");
            clearInterval(interval);
            setSemaphore(false);
            resolve();
          } else {
            Logger.debug()("useRetryFetch next wait cycle ....", url);
            if (!semaphore()) {
              clearInterval(interval);
              Logger.debug()("useRetryFetch stopped waiting, can run now", url);
              resolve();
            }
          }
        }, 200);
      });
    } else {
      Logger.debug()("useRetryFetch starts without waiting", url);
      setSemaphore(true);
    }

    if (!options) options = {};
    if (!options.method) options.method = "GET";
    if (!options.credentials) options.credentials = "include";
    if (!options.headers) options.headers = {};
    if (!options.headers["Authorization"])
      options.headers["Authorization"] = `Bearer ${accessToken()}`;
    if (!options.headers["Content-Type"])
      options.headers["Content-Type"] = "application/json";
    options.signal = locAbortController.signal;

    Logger.info()("useRetryFetch requesting", url);
    let response = await fetch(url, options);

    if (response.status === 403) {
      Logger.debug()(
        "Detected FORBIDDEN. Get new access token and then retry ...."
      );
      try {
        let newAccessToken = await refresh();
        options.headers["Authorization"] = `Bearer ${newAccessToken}`;
        response = await fetch(url, options);
      } catch (e) {
        sessionStorage.removeItem("semaphore");
      }
    }

    // await delay(100);  // simulate long-time requests (like on dev server)

    Logger.info()("useRetryFetch finished", url);
    setSemaphore(false);
    return response;
  };
};

export default useRetryFetch;
