import { useRef, useState, useEffect, useContext, useMemo } from "react";
import useRetryFetch from "../hooks/useRetryFetch";
import ReactFlow, {
  Background,
  ConnectionLineType,
  useNodesInitialized,
} from "reactflow";
import Activity from "./reactflow/Activity";
import CustomEdge from "./reactflow/CustomEdge";
import { MyContext } from "./reactflow/MyContextProvider";
import { directionService } from "../services/directionService";
import loadingImage from "../images/loading.gif";

import LoggerContext from "../context/LoggerProvider";

import "reactflow/dist/style.css";

import "./ReadOnlyView.css";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { config } from "../Environment";
import { LANGUAGES } from "../lib/utils";

const HIGHLIGHT_TYPE = {
  NONE: 0,
  ACTIVITY: 1,
  EDGE: 2,
};

const ReadOnlyView = ({ presHistoryId, highlightId, smaller }) => {
  const {
    nodes,
    edges,
    data,
    setData,
    onNodesChange,
    setNodes,
    setEdges,
    designDirection,
    setDesignDirection,
    transpose,
    setPrescription,
    prescriptionLanguage,
  } = useContext(MyContext);

  const { Logger } = useContext(LoggerContext);

  const retryFetch = useRetryFetch();

  const [isLoading, setLoading] = useState(false);

  const nodesInitialized = useNodesInitialized({
    includeHiddenNodes: false,
  });

  const [nodesDueSelecting, setNodesDueSelecting] = useState(false);
  const [nodesDueUnSelecting, setNodesDueUnSelecting] = useState(false);

  const highlightInfoRef = useRef({ type: HIGHLIGHT_TYPE.NONE });

  const nodeTypes = useMemo(() => ({ activity: Activity }), []);
  const edgeTypes = useMemo(
    () => ({
      customedge: CustomEdge,
    }),
    []
  );

  const initReactFlow = (unsavedGraph) => {
    sessionStorage.removeItem("processID");
    sessionStorage.removeItem("nextActivity");

    let isRTL = document.body.dir === "rtl";

    Logger.debug()("useEffect[] is running in RTL mode? " + isRTL);

    // load model from database
    const loadPrescription = async () => {
      let restString = "";

      if (presHistoryId) {
        // loading from presHistoryId
        setLoading(true);

        let response = await retryFetch(
          `${config.API_BASE}/api/prescriptionhistory/${presHistoryId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          let pres = await response.json();

          restString = pres.diagramJSON;

          Logger.debug()(restString);
        }
      } else {
        let id = sessionStorage.getItem("prescriptionID");
        if (id) {
          setLoading(true);

          let response = await retryFetch(
            `${config.API_BASE}/api/prescriptions/${id}`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          if (response.ok) {
            let pres = await response.json();

            restString = pres.diagramJSON;

            Logger.debug()(restString);
          }
        }
      }

      if (restString) {
        await setPrescription(restString);
      }

      setLoading(false);

      if (highlightId) {
        highlightActivity(highlightId);
      }
    };

    loadPrescription();
  };

  useEffectOnce(() => {
    initReactFlow();

    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        Logger.info()("Direction change detected in DesignView");

        setDesignDirection(document.body.dir);
        setNodes((prevNodes) => {
          if (prevNodes.length)
            Logger.debug()(
              "transpose called from Direction change in ReadOnlyView"
            );
          return transpose(prevNodes);
        });
        setEdges((prevEdges) => {
          let cloned = [...prevEdges];
          for (let i = 0; i < cloned.length; i++) {
            let clonedEdge = { ...cloned[i] };
            cloned[i] = clonedEdge;
          }
          return cloned;
        });
        setNodesDueSelecting(true);
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (nodesDueSelecting) {
      setTimeout(() => {
        Logger.debug()("useEffect: nodes due selecting!");

        // Workaround: Simulate selecting every node
        let changes = [];
        for (let node of nodes) {
          changes.push({
            id: node.id,
            type: "select",
            selected: true,
          });
        }
        onNodesChange(changes);
        setNodesDueSelecting(false);
        setNodesDueUnSelecting(true);
      }, 10);
    } else if (nodesDueUnSelecting) {
      setTimeout(() => {
        Logger.debug()("useEffect: nodes due unselecting!");

        // Workaround: Simulate unselecting every node
        let changes = [];
        for (let node of nodes) {
          changes.push({
            id: node.id,
            type: "select",
            selected: false,
          });
        }
        onNodesChange(changes);
        setNodesDueUnSelecting(false);

        highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
      }, 10);
    }
  }, [nodes, nodesDueSelecting, nodesDueUnSelecting]);

  useEffect(() => {
    if (highlightId) {
      let actId = highlightId.split("|")[0];
      if (data[actId]) {
        highlightActivity(highlightId);
      }
    }
  }, [data, highlightId]);

  const getStartActivity = () => {
    let result = "";

    let activityKeys = Object.keys(data);
    for (let actID of activityKeys) {
      let actData = data[actID];
      if (actData.type === "start") {
        result = actID;
        break;
      }
    }

    return result;
  };

  const getEdgeIDByActOpt = (actId, optId) => {
    Logger.debug()("GETTING EDGE by act and opt", actId, optId, data);

    let result = "";

    if (data[actId]) {
      let sourceHandle = data[actId].options.find(
        (option) => option.value === optId
      )?.handle;

      if (sourceHandle) {
        result =
          edges.find((edge) => edge.sourceHandle === sourceHandle)?.id || "";
      }
    }

    return result;
  };

  const highlightActivity = (nodeId) => {
    let optionEdgeId = "";
    if (nodeId === "start") {
      nodeId = getStartActivity();
    } else {
      let actOpt = nodeId.split("|");
      nodeId = actOpt[0];
      optionEdgeId = getEdgeIDByActOpt(nodeId, actOpt[1]);
    }
    Logger.debug()(
      "Activity and edge will be highlighted",
      nodeId,
      optionEdgeId
    );
    setData((oldData) => {
      let newData = { ...oldData };
      newData[nodeId] = { ...newData[nodeId] };
      // reset all other nodes
      for (let dataNodeId of Object.keys(newData)) {
        newData[dataNodeId].selected = dataNodeId === nodeId;
      }
      if (optionEdgeId) {
        newData[optionEdgeId] = { selected: true };
      }

      Logger.debug()("Result in data of highlighting", newData);

      return newData;
    });
  };

  useEffect(() => {
    if (highlightId) {
      Logger.debug()("highlightId was changed to", highlightId);
      highlightActivity(highlightId);
    }
  }, [highlightId]);

  const initiateTranspose = () => {
    setNodes((prevNodes) => {
      if (prevNodes.length) Logger.debug()("TRANSPOSE from initiateTranspose");
      return transpose(prevNodes);
    });
    setEdges((prevEdges) => {
      let cloned = [...prevEdges];
      for (let i = 0; i < cloned.length; i++) {
        let clonedEdge = { ...cloned[i] };
        cloned[i] = clonedEdge;
      }
      return cloned;
    });
    setNodesDueSelecting(true);
  };

  useEffect(() => {
    if (
      nodesInitialized &&
      designDirection !== LANGUAGES[prescriptionLanguage].dir
    ) {
      initiateTranspose();
    }
  }, [nodesInitialized]);

  return (
    <div className="flexArea">
      <div
        style={{
          width: smaller ? "55vw" : "100vw",
          height: "80vh",
          margin: "0px 40px 0px 40px",
          lineHeight: "1.5rem",
          backgroundColor: "lightgray",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          dir="ltr"
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
          edgesUpdatable={false}
          edgesFocusable={false}
          nodesDraggable={false}
          nodesConnectable={false}
          nodesFocusable={false}
          draggable={false}
          panOnDrag={true}
          elementsSelectable={false}
        >
          <Background variant="lines" color="#c9c9c9" />
        </ReactFlow>
      </div>
      {isLoading ? (
        <div className="loadingcenter">
          <img src={loadingImage} alt="" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReadOnlyView;
