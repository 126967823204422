import { useContext } from "react";
import { MyContext } from "./MyContextProvider";
import { BaseEdge, getSmoothStepPath } from "reactflow";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  selected,
  sourcePosition,
  targetPosition,
  markerEnd,
}) {
  const { data } = useContext(MyContext);

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          stroke: `${
            selected ? "red" : data[id]?.selected ? "#ff9000" : "black"
          }`,
        }}
        markerEnd={markerEnd}
      />
    </>
  );
}
