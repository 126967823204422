import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Login.css";

import { config } from "../Environment";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

function ForgotPassword() {
  const emailRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    setSuccessMsg("");
    setErrMsg("");
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      emailRef.current.focus();
      return setErrMsg(t("msg_error_email_required"));
    }

    let response = await fetch(config.API_BASE + "/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
      credentials: "include",
    });
    if (response.ok) {
      setSuccessMsg(t("msg_if_email_reset"));
    }
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="h-full w-full flex items-start lg:items-center justify-start py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t("forgot_password")}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">{t("email_address")}</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                onChange={changeEmail}
                ref={emailRef}
                value={email}
                required
              />
            </div>
            {errMsg ? <div className="error">{errMsg}</div> : ""}
            {successMsg ? <div className="success">{successMsg}</div> : ""}
            <Button type="submit" className="w-full">
              {t("submit")}
            </Button>
          </div>
        </form>
        <div className="mt-3 text-center text-sm">
          {t("dont_have_an_account")}
          <Link to="/register" className="underline ms-2">
            {t("sign_up")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
