import { useEffect, useContext, useState, useRef } from "react";
import {
  Handle,
  Position,
  useNodeId,
  useStore,
  getConnectedEdges,
} from "reactflow";
import { MyContext } from "./MyContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/useOutsideClick";
import LoggerContext from "../../context/LoggerProvider";
import { Table } from "lucide-react";
const OPTION_ACTIONS = { REMOVE: 1, EDIT: 2 };

const selector = (s) => ({
  nodeInternals: s.nodeInternals,
});

function Activity({ selected = false }) {
  const { t } = useTranslation();

  const { Logger } = useContext(LoggerContext);

  const elementRef = useRef(null);
  const labelInputRef = useRef(null);
  const labelOptionRef = useRef(null);

  const {
    data,
    setData,
    edges,
    setShowModal,
    setShowDataItemMapping,
    setSelectableContext,
    setDataItemMapping,
    setSelectedMapping,
    setMappingNodeId,
    setSelectedOption,
    selectedOption,
    setSelectedActivity,
    setShowOptionEdit,
    labelBeingEdited,
    setLabelBeingEdited,
    toBeSaved,
    setToBeSaved,
    designDirection,
    prescriptionLanguage,
    setShowActivityEdit,
    flowContext,
    generateFlowcontextFromData,
    paneWasClicked,
    setPaneWasClicked,
  } = useContext(MyContext);
  const nodeId = useNodeId();

  const { nodeInternals } = useStore(selector);

  // const [labelEditMode, setLabelEditMode] = useState(false);
  const [optionInlineEditIdx, setOptionInlineEditIdx] = useState(-1);
  const [tempOptionLabel, setTempOptionLabel] = useState("");
  const [labelText, setLabelText] = useState("");

  const onOptionRemove = (idx) => {
    // find edges to this option
    let handleId = `${nodeId}-${idx}`;
    let hasEdge = edges.find((edge) => edge.sourceHandle === handleId);

    if (!hasEdge) {
      setSelectedOption({});
      setData((prevData) => {
        let cloned = { ...prevData };
        cloned[nodeId] = { ...cloned[nodeId] };
        cloned[nodeId].options.splice(idx, 1);
        return cloned;
      });
    } else {
      setSelectedOption({ nodeId, idx, action: OPTION_ACTIONS.REMOVE });
    }
  };

  const onOptionAdd = () => {
    setData((prevData) => {
      let cloned = { ...prevData };
      cloned[nodeId] = { ...cloned[nodeId] };
      cloned[nodeId].options.push({
        text: {
          en: "",
          de: "",
          he: "",
        },
        value: `option${Math.floor(Math.random() * 10000)}`,
        handle: `${nodeId}-${cloned[nodeId].options.length}`,
      });
      return cloned;
    });
  };

  const handleLabelChange = (e) => {
    setLabelText(e.target.value);
  };

  const handleSave = () => {
    Logger.debug()(
      "HANDLESAVE: LABEL of activity should be saved",
      nodeId,
      labelInputRef.current?.value
    );
    setLabelBeingEdited(null);
    setData((prevData) => {
      let cloned = { ...prevData[nodeId] };
      cloned.label[prescriptionLanguage] = labelInputRef.current?.value;
      prevData[nodeId] = cloned;
      return prevData;
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
    if (e.key === "Escape") {
      setLabelBeingEdited(null);
      setLabelText(data[nodeId].label[prescriptionLanguage]);
    }
    e.stopPropagation();
  };

  const saveOptionLabel = () => {
    setData((prevData) => {
      let newData = { ...prevData };
      newData[nodeId] = { ...newData[nodeId] };
      newData[nodeId].options = [...newData[nodeId].options];
      newData[nodeId].options[optionInlineEditIdx] = {
        ...newData[nodeId].options[optionInlineEditIdx],
      };
      newData[nodeId].options[optionInlineEditIdx].text = {
        ...newData[nodeId].options[optionInlineEditIdx].text,
      };
      newData[nodeId].options[optionInlineEditIdx].text[prescriptionLanguage] =
        tempOptionLabel;
      return newData;
    });

    setOptionInlineEditIdx(-1);
  };

  const handleOptionKeyDown = (e) => {
    if (e.key === "Enter") {
      // data[nodeId].options[optionInlineEditIdx].text[prescriptionLanguage] updaten
      saveOptionLabel();
    }
    if (e.key === "Escape") {
      setOptionInlineEditIdx(-1);
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (selectedOption.nodeId) {
      switch (selectedOption.action) {
        case OPTION_ACTIONS.REMOVE:
          setShowModal(true);
          break;
        case OPTION_ACTIONS.EDIT:
          setShowOptionEdit(true);
          break;
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (paneWasClicked && optionInlineEditIdx !== -1) {
      saveOptionLabel();
      setPaneWasClicked(false);
    }
  }, [paneWasClicked]);

  useEffect(() => {
    setLabelText(data[nodeId].label[prescriptionLanguage]);
  }, [data]);

  const isHandleConnectable = (handleId) => {
    const node = nodeInternals.get(nodeId);
    const connectedEdges = getConnectedEdges([node], edges);

    let result = true;
    for (let edge of connectedEdges) {
      if (edge.sourceHandle === handleId) {
        result = false;
      }
    }
    return result;
  };

  const changeActivityType = () => {
    if (data[nodeId].type === "default") {
      setData((prevData) => {
        let clonedData = { ...prevData };
        for (let nodeData in clonedData) {
          let clonedNodeData = { ...clonedData[nodeData] };
          if (clonedNodeData.type !== "prescription") {
            clonedNodeData.type = "default";
          }
          clonedData[nodeData] = clonedNodeData;
        }
        let myclonednode = { ...clonedData[nodeId] };
        myclonednode.type = "start";
        clonedData[nodeId] = myclonednode;
        return clonedData;
      });
    }
  };

  const getBackgroundColorForType = (type) => {
    switch (type) {
      case "start":
        return "#009165";
      case "default":
        return "#0035ef";
      case "prescription":
        return "#cf7500";
    }
  };

  const getBackgroundForType = (type) => {
    return `linear-gradient(180deg, rgba(195,195,195,1) 0%, ${getBackgroundColorForType(
      type
    )} 15%, ${getBackgroundColorForType(type)} 100%)`;
  };

  const getActivityTypeLabel = (type) => {
    return (
      <div
        className={
          designDirection === "ltr" ? "bottomrighttext" : "bottomlefttext"
        }
        onDoubleClick={changeActivityType}
      >
        {t(type)}
      </div>
    );
  };

  const handlePaneClickSimulation = () => {
    console.log("PANE CLICK SIMULATED");
    setPaneWasClicked(true);
  };

  useOutsideClick(labelInputRef, handleSave);
  useOutsideClick(labelOptionRef, handlePaneClickSimulation);

  useEffect(() => {
    if (toBeSaved && labelBeingEdited === nodeId) {
      handleSave();
      setToBeSaved(false);
    }
  }, [toBeSaved]);

  return (
    <div>
      <Handle
        type="target"
        id={`${nodeId}-targetSideTop`}
        position={designDirection === "ltr" ? Position.Left : Position.Right}
        style={{
          top: "25px",
          backgroundColor: getBackgroundColorForType(data[nodeId].type),
          padding: "9px",
          right: designDirection === "ltr" ? "inherit" : "-5px",
          left: designDirection === "rtl" ? "inherit" : "-5px",
          border: "1px solid lightgray",
          zIndex: "-1",
        }}
      />
      <div
        style={{
          padding: "1px",
          border: `${
            selected
              ? "2px solid red"
              : data[nodeId].selected
              ? "2px solid #ff9000"
              : "none"
          }`,
          borderRadius: "10px",
        }}
        ref={elementRef}
        dir={designDirection}
      >
        <div
          style={{
            borderRadius: "10px",
            background: getBackgroundForType(data[nodeId].type),
            padding: "0",
            color: "white",
          }}
        >
          <div
            style={{
              fontSize: "0.9em",
              textAlign: "center",
              padding: "5px 15px 5px 15px",
              minWidth: "150px",
            }}
            onClick={() => {
              if (data[nodeId].type !== "prescription")
                setLabelBeingEdited(nodeId);
            }}
          >
            {labelBeingEdited === nodeId ? (
              <input
                ref={labelInputRef}
                type="text"
                value={labelText}
                onChange={handleLabelChange}
                onKeyDown={handleKeyDown}
                style={{
                  width: "100%",
                  margin: "0",
                  border: "none",
                  outline: "none",
                  background: "none",
                  textAlign: "center",
                  color: "white",
                  fontFamily: "inherit",
                }}
                autoFocus
              />
            ) : (
              <span>
                {data[nodeId].label[prescriptionLanguage]
                  ? data[nodeId].label[prescriptionLanguage]
                  : t("no_title")}
              </span>
            )}
          </div>
          {data[nodeId].options.map((option, idx) => (
            <div key={`option-${nodeId}-${idx}`}>
              <div
                style={{
                  fontSize: "0.75em",
                  backgroundColor: "#b3c3fa",
                  minWidth: "100px",
                  borderRadius: "3px",
                  padding:
                    designDirection === "ltr"
                      ? "0px 10px 2px 2px"
                      : "0px 2px 2px 10px",
                  color: "black",
                }}
                onDoubleClick={(e) => {
                  setOptionInlineEditIdx(-1);
                  e.stopPropagation();
                  if (data[nodeId].type !== "prescription") {
                    setSelectedOption({
                      nodeId,
                      idx,
                      action: OPTION_ACTIONS.EDIT,
                    });
                  }
                }}
              >
                {data[nodeId].options.length > 1 ? (
                  <button
                    style={{
                      color: `${
                        data[nodeId].type !== "prescription"
                          ? "white"
                          : "#b3c3fa"
                      }`,
                      fontSize: "1.5em",
                      fontWeight: "bold",
                      backgroundColor: "rgba(0,0,0,0)",
                      padding: "0px 3px",
                    }}
                    className="nodrag"
                    onClick={() => {
                      if (data[nodeId].type !== "prescription")
                        onOptionRemove(idx);
                    }}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    -
                  </button>
                ) : (
                  <></>
                )}
                {data[nodeId].type !== "prescription" &&
                optionInlineEditIdx === idx ? (
                  <input
                    ref={labelOptionRef}
                    className="inlineedit"
                    type="text"
                    style={{
                      width: "90%",
                      margin: "0",
                      border: "none",
                      outline: "none",
                      backgroundColor: "rgba(250, 250, 250, 0.3)",
                      fontFamily: "inherit",
                    }}
                    autoFocus={true}
                    value={tempOptionLabel}
                    onChange={(e) => setTempOptionLabel(e.target.value)}
                    onKeyDown={handleOptionKeyDown}
                  />
                ) : (
                  <span
                    onClick={(e) => {
                      setTempOptionLabel(option.text[prescriptionLanguage]);
                      setOptionInlineEditIdx(idx);
                    }}
                  >
                    {!!option.text[prescriptionLanguage]
                      ? option.text[prescriptionLanguage]
                      : t("no_title")}
                  </span>
                )}
              </div>
              {option.value !== "?????" ? (
                <Handle
                  type="source"
                  position={
                    designDirection === "ltr" ? Position.Right : Position.Left
                  }
                  id={`${nodeId}-${idx}`}
                  style={{
                    top: `${idx * 28 + 48}px`,
                    padding: "8px",
                    left: designDirection === "ltr" ? "inherit" : "-9px",
                    right: designDirection === "rtl" ? "inherit" : "-9px",
                    border: "none",
                    backgroundColor: "#b3c3fa",
                  }}
                  isConnectable={isHandleConnectable(`${nodeId}-${idx}`)}
                />
              ) : (
                <Handle
                  type="source"
                  position={
                    designDirection === "ltr" ? Position.Right : Position.Left
                  }
                  id={`${nodeId}-${idx}`}
                  style={{
                    top: `${idx * 28 + 48}px`,
                    padding: "8px",
                    left: designDirection === "ltr" ? "inherit" : "-9px",
                    right: designDirection === "rtl" ? "inherit" : "-9px",
                    border: "none",
                    backgroundColor: "red",
                  }}
                  isConnectable={false}
                />
              )}
            </div>
          ))}
          {data[nodeId].type !== "prescription" ? (
            <button
              style={{
                color: "white",
                padding: "0px 3px 2px 3px",
                backgroundColor: getBackgroundColorForType(data[nodeId].type),
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              onClick={onOptionAdd}
            >
              +
            </button>
          ) : (
            <span>&nbsp;</span>
          )}
          {data[nodeId].type === "prescription" &&
          data[nodeId].dataitemmapping?.length ? (
            <Table
              onClick={async () => {
                setDataItemMapping(data[nodeId].dataitemmapping);
                let selectableContext = [];
                let referenceFlowContext = structuredClone(flowContext);
                await generateFlowcontextFromData(
                  data,
                  selectableContext,
                  "",
                  nodeId,
                  referenceFlowContext
                );

                let alreadySelected = [];
                for (let i = 0; i < data[nodeId].dataitemmapping.length; i++) {
                  alreadySelected.push(
                    data[nodeId].dataitemmapping[i].mappedToOuter.key
                  );
                }

                setSelectedMapping(alreadySelected);
                setSelectableContext(selectableContext);
                setShowDataItemMapping(true);
                setMappingNodeId(nodeId);
              }}
              className="float-start text-xs mt-1 ml-1 mr-1"
              size="18px"
            />
          ) : (
            <></>
          )}
          {getActivityTypeLabel(data[nodeId].type)}
        </div>
      </div>
      {selected && data[nodeId].type !== "prescription" ? (
        <>
          <FontAwesomeIcon
            icon={solid("edit")}
            style={{
              position: "absolute",
              top: "-25px",
              left: "90px",
              fontSize: "1.2em",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowActivityEdit(true);
              setSelectedActivity(nodeId);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Activity;
