import { useRef, useEffect, forwardRef } from "react";

const AutogrowTextarea = forwardRef((props, outsideTextareaRef) => {
  const {
    minHeight = 60,
    value,
    onChange,
    onKeyDown,
    autoFocus,
    inlinemode,
  } = props;

  const localTextareaRef = useRef();
  const autogrowTextareaRef = outsideTextareaRef || localTextareaRef;

  useEffect(() => {
    if (autogrowTextareaRef.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      autogrowTextareaRef.current.style.height = "0px";
      let scrollHeight = autogrowTextareaRef.current.scrollHeight;

      if (scrollHeight < minHeight) scrollHeight = minHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will produce an incorrect value.
      autogrowTextareaRef.current.style.height =
        scrollHeight + (inlinemode ? 0 : 10) + "px";
    }
  }, [autogrowTextareaRef, value]);

  return (
    <textarea
      className={
        inlinemode
          ? "block m-0 p-0 w-full text-sm bg-secondary placeholder-gray-400 text-[length:inherit] leading-[inherit] focus:ring-blue outline-none resize-none"
          : "block mt-2 px-2.5 py-1.5 w-full text-sm rounded-lg border focus:border-blue-500 bg-secondary border-gray-600 placeholder-gray-400 text-black focus:ring-blue outline-none resize-none"
      }
      rows="1"
      cols="50"
      ref={autogrowTextareaRef}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
    />
  );
});

export default AutogrowTextarea;
