import { useState, useEffect, useContext } from "react";
import Textbox from "./activityComponents/Textbox";
import TextArea from "./activityComponents/TextArea";
import Checkboxes from "./activityComponents/Checkboxes";
import Gauge from "./activityComponents/Gauge";
import Timer from "./activityComponents/Timer";
import ActivitySlider from "./activityComponents/ActivitySlider";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Skeleton } from "./ui/skeleton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import LoggerContext from "../context/LoggerProvider";
import { convertOldCustomToNew, LANGUAGES } from "../lib/utils";

import "./RenderDialog.css";

const RenderDialog = ({
  diagram,
  initialActivity,
  onDone,
  onPause,
  onNextActivity,
  onNestedActivity,
  onBackFromNested,
  onBackToFinishedNested,
  initialStepStack,
  userData,
  setUserData,
  processId,
}) => {
  const [currentActivity, setCurrentActivity] = useState("");
  const [currentActivityData, setCurrentActivityData] = useState({});
  // const [userData, setUserData] = useState({});

  const [stepStack, setStepStack] = useState([]); // in order to allow to go back in "step history"

  const [nestedActivityIntent, setNestedActivityIntent] = useState({});
  const [finishedNextActivity, setFinishedNextActivity] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { Logger } = useContext(LoggerContext);

  const handleOptionClick = async (optIdx) => {
    let currentOption = currentActivityData?.options?.[optIdx];
    let nextAct = currentOption.nextActivities[0];
    // push currentActivity to stack
    let newStepStack = [...stepStack];
    newStepStack.push({
      activity: currentActivity,
      userData,
    });
    setStepStack(newStepStack);
    if (nextAct) {
      // jump to next activity
      setCurrentActivity(nextAct);

      setFinishedNextActivity(false);
      await onNextActivity(currentOption.value, nextAct, newStepStack);
      setFinishedNextActivity(true);
      //setUserData({});

      // Is next activity a prescription?
    } else {
      await onDone(currentOption.value, userData, newStepStack);
    }
  };

  const handleCloseClick = () => {
    onPause();
  };

  const handleBackClick = async () => {
    if (stepStack.length) {
      let newStack = [...stepStack];
      let prevAct = newStack.pop();

      let theActivity =
        typeof prevAct === "string" ? prevAct : prevAct.activity;

      if (theActivity === "TERMINATE") {
        // going back from nested to outer prescription
        // will terminate the inner one
        await onBackFromNested();
        // setUserData({});
      } else if (theActivity.includes("REOPEN")) {
        // stepping back from outer to (already finished) nested prescription
        // Reopen and use latest step from it
        let nestedProcessId = theActivity.split("|")[1];
        Logger.debug()(
          "REOPEN detected for nested process id: " + nestedProcessId
        );
        await onBackToFinishedNested(nestedProcessId, newStack);
      } else {
        // update stack
        setStepStack(newStack);

        // jump to next activity
        setCurrentActivity(theActivity);

        onNextActivity("GOINGBACK", theActivity, newStack);
        setUserData(prevAct.userData ? prevAct.userData : {});
      }
    }
  };

  const handleStringValueChange = (key, value) => {
    setUserData((oldUserData) => {
      let newUserData = { ...oldUserData };
      newUserData[key] = value;
      return newUserData;
    });
  };

  const handleOptionValueChange = (key, value) => {
    setUserData((oldUserData) => {
      let newUserData = { ...oldUserData };
      newUserData[key] = value;
      return newUserData;
    });
  };

  const interpolate = (content) => {
    let result = content;

    let matches = result.match(/\{.*?\}/g);

    for (let match of matches) {
      let variableName = match.match(/\{(.*)\}/)[1];
      let transformed = structuredClone(userData[variableName]);
      if (typeof transformed !== "string") {
        transformed = JSON.stringify(transformed);
      }
      let newPart = "<b>" + transformed + "</b>";
      result = result.replace(match, newPart);
    }
    return result;
  };

  const renderItem = (item) => {
    switch (item.type) {
      // informative elements
      case "interpolation":
        return (
          <div
            className="mt-4 text-start"
            dangerouslySetInnerHTML={{ __html: interpolate(item.content) }}
          ></div>
        );
      case "richtext":
      case "richtextnew":
        return (
          <div
            className="ProseMirror mt-4 text-start"
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></div>
        );
      case "hr":
        return <hr />;
      case "headline1":
        return <h2 className="text-2xl text-bold">{item.content}</h2>;
      case "headline2":
        return (
          <h3 className="text-lg my-1.5 leading-tight text-start">
            {item.content}
          </h3>
        );
      case "paragraph":
        return <p>{item.content}</p>;
      case "orderedlist":
        return (
          <div className="optioncomponent ptop text-start">
            <Label className="font-semibold">{item.content.label}</Label>
            <ol style={{ listStyleType: "decimal", marginLeft: "16px" }}>
              {item.content.items.map((olitem) => (
                <li key={olitem.key}>{olitem.label}</li>
              ))}
            </ol>
          </div>
        );
      // interactive elements
      case "textbox":
        return (
          <Textbox
            itemkey={item.id}
            content={item.content}
            defaultValue={
              userData[item.content.key] ||
              diagram.flowcontext?.find((entry) => entry.key === item.id)
                ?.defaultValue ||
              ""
            }
            onChange={handleStringValueChange}
          />
        );
      case "textarea":
        return (
          <TextArea
            itemkey={item.id}
            content={item.content}
            defaultValue={
              userData[item.content.key] ||
              diagram.flowcontext?.find((entry) => entry.key === item.id)
                ?.defaultValue ||
              ""
            }
            onChange={handleStringValueChange}
          />
        );
      case "checkbox":
        return (
          <Checkboxes
            itemkey={item.id}
            content={item.content}
            defaultValue={userData[item.content.key] || ""}
            onChange={handleOptionValueChange}
          />
        );
      case "gauge":
        return (
          <Gauge
            itemkey={item.id}
            content={item.content}
            defaultValue={userData[item.content.key] || {}}
            onChange={handleOptionValueChange}
          />
        );
      case "timer":
        return (
          <Timer
            itemkey={item.id}
            content={item.content}
            defaultValue={userData[item.content.key] || "00:00"}
            onChange={handleStringValueChange}
          />
        );
      case "slider":
        return (
          <ActivitySlider
            itemkey={item.id}
            content={item.content}
            defaultValue={userData[item.content.key] || ""}
            onChange={handleStringValueChange}
          />
        );
      default:
    }
  };

  const renderActivityComponents = () => {
    let components = currentActivityData?.custom;

    // NEW: components must already be converted!!
    if (components) {
      if (Array.isArray(components)) {
        return (
          <>
            {components.map((item) => (
              <div className="mx-5" key={item.id}>
                {renderItem(item)}
              </div>
            ))}
          </>
        );
      } else {
        // old custom type found, should NEVER be the case
        Logger.error()(
          "Old custom data in currentActivityData found, should have been converted in useMemo function already!"
        );
      }
    } else {
      return <></>;
    }

    // if (components) {
    //   if (Array.isArray(components)) {
    //     return (
    //       <>
    //         {components.map((item) => (
    //           <div key={item.id}>{renderItem(item)}</div>
    //         ))}
    //       </>
    //     );
    //   } else {
    //     // old custom type found
    //     components = convertOldCustomToNew(components);
    //     return (
    //       <>
    //         {components.map((item) => (
    //           <div key={item.id}>{renderItem(item)}</div>
    //         ))}
    //       </>
    //     );
    //   }
  };

  useEffect(() => {
    if (initialActivity) setIsLoading(false);
    Logger.debug()(
      "RenderDialog with initialActivity and diagram data",
      initialActivity,
      diagram.nodes,
      diagram.edges
    );
    setCurrentActivity(initialActivity);
  }, [initialActivity]);

  useEffect(() => {
    Logger.debug()("RenderDialog with initialStepStack", initialStepStack);
    setStepStack(initialStepStack);
  }, [initialStepStack]);

  useEffect(() => {
    (async function () {
      Logger.debug()(
        "Data from current activity will be detected",
        currentActivity,
        diagram.data,
        diagram.edges
      );
      let theData = {};
      if (currentActivity && diagram.data && diagram.edges) {
        theData = diagram.data?.[currentActivity];

        if (theData) {
          if (theData.type !== "prescription" || !processId) {
            // find handle as sourceHandle in edges, if necessary
            for (let option of theData.options) {
              if (option.handle) {
                let nextActivities = [];

                for (let edge of diagram.edges) {
                  if (edge.sourceHandle === option.handle) {
                    nextActivities.push(edge.target);
                  }
                }
                delete option.handle; // remove handle, so no additional search necessary
                option.nextActivities = nextActivities;
              }
            }
          }
          if (theData.custom && !Array.isArray(theData.custom)) {
            theData.custom = convertOldCustomToNew(theData.custom);
          }

          Logger.debug()("New current activity data", theData);

          if (theData.type === "prescription") {
            Logger.debug()(
              "Inner nested prescription detected",
              theData.historyId,
              theData.options
            );

            setNestedActivityIntent({
              historyId: theData.historyId,
              options: theData.options,
              currentActivity,
            });
          }
        }
      }

      setCurrentActivityData(theData);
    })();
  }, [diagram, currentActivity]);

  useEffect(() => {
    (async function () {
      if (nestedActivityIntent.historyId && finishedNextActivity) {
        await onNestedActivity(
          nestedActivityIntent.historyId,
          nestedActivityIntent.options,
          nestedActivityIntent.currentActivity
        );

        setFinishedNextActivity(false);
        setNestedActivityIntent({});
      }
    })();
  }, [finishedNextActivity, nestedActivityIntent]);

  return (
    <>
      {currentActivityData?.type !== "prescription" || !processId ? (
        <div className="fixed mx-auto my-0 bg-white rounded-lg text-center overflow-scroll max-h-[calc(100vh_-_64px)] scroll-smooth no-scrollbar md:max-h-[calc(100vh_-_144px)] top-1/2 left-0 right-0 -translate-y-1/2 md:max-w-[600px] border shadow-xl">
          <div className="sticky top-0 h-10 bg-primary flex flex-row justify-between items-center shdaow-2xl z-10">
            <div
              className={`text-lg ms-2 text-white ${
                !stepStack.length ? "invisible" : "visible"
              }`}
              onClick={handleBackClick}
            >
              <FontAwesomeIcon
                icon={solid("arrow-left")}
                className={
                  LANGUAGES[diagram.prescriptionLanguage]?.dir === "rtl"
                    ? "rtl:rotate-180"
                    : ""
                }
              />
            </div>
            <h2 className="text-xl text-white text-bold">
              {isLoading ? (
                <Skeleton className="h-[20px] w-[250px] rounded-xl bg-gray-200" />
              ) : (
                currentActivityData?.label?.[diagram.prescriptionLanguage]
              )}
            </h2>
            <div className="text-lg me-2 text-white" onClick={handleCloseClick}>
              <FontAwesomeIcon icon={solid("close")} />
            </div>
          </div>
          {isLoading ? (
            <div className="p-4">
              <div className="flex flex-col space-y-3">
                <Skeleton className="h-[125px] w-[90%] rounded-xl bg-gray-200" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[90%]" />
                  <Skeleton className="h-4 w-[80%]" />
                </div>
              </div>
            </div>
          ) : (
            renderActivityComponents()
          )}
          {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}
          <div className="w-full sticky bottom-0 py-2 bg-secondary mt-5">
            {currentActivityData?.options?.map((option, optIdx) => (
              <Button
                className="optionbuttons mb-1"
                key={option.value}
                onClick={() => handleOptionClick(optIdx)}
              >
                {option.text[diagram.prescriptionLanguage]}
              </Button>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RenderDialog;
