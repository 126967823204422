import { createContext, useState, useCallback, useEffect } from "react";
import { useNodesState, useEdgesState } from "reactflow";
import useRetryFetch from "../../hooks/useRetryFetch";
import convertPrescription from "./convert_prescription";
import cookies from "js-cookie";
import { config } from "../../Environment";
import { convertOldCustomToNew } from "../../lib/utils";

const MyContext = createContext();

function transpose(nodes) {
  let result = [...nodes];

  // find horizontal center of node elements
  let min = Infinity;
  let max = -Infinity;
  for (let node of result) {
    if (node.position.x < min) {
      min = node.position.x;
    }
    if (node.position.x > max) {
      max = node.position.x;
    }
  }
  let centerX = Math.floor(min + (max - min) / 2);

  for (let node of result) {
    let clonedPosition = { ...node.position };
    clonedPosition.x = centerX + (centerX - clonedPosition.x);
    node.position = clonedPosition;
  }

  return result;
}

function MyContextProvider({ children }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDataItemMapping, setShowDataItemMapping] = useState(false);
  const [showOptionEdit, setShowOptionEdit] = useState(false);
  const [showRemoveActivity, setShowRemoveActivity] = useState(false);
  const [showOptionI18N, setShowOptionI18N] = useState(false);
  const [showActivityLabelI18N, setShowActivityLabelI18N] = useState(false);
  const [showActivityEdit, setShowActivityEdit] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedActivity, setSelectedActivity] = useState("");
  const [dataItemMapping, setDataItemMapping] = useState([]);
  const [mappingNodeId, setMappingNodeId] = useState("");
  const [selectedMapping, setSelectedMapping] = useState([]);
  const [selectableContext, setSelectableContext] = useState([]);
  const [designDirection, setDesignDirection] = useState(document.body.dir);
  const [data, setData] = useState({});
  const [labelBeingEdited, setLabelBeingEdited] = useState(null);
  const [toBeSaved, setToBeSaved] = useState(false);

  const [flowContext, setFlowContext] = useState([]);
  const [contract, setContract] = useState([]);

  const [prescriptionLanguage, setPrescriptionLanguage] = useState(
    cookies.get("i18next").split("-")[0] || "en"
  );
  const [globalLanguage, setGlobalLanguage] = useState(
    cookies.get("i18next").split("-")[0] || "en"
  );

  const [paneWasClicked, setPaneWasClicked] = useState(false);

  const retryFetch = useRetryFetch();

  const generateFlowcontextFromData = async (
    presData,
    result,
    subPresName = "",
    nodeidToIgnore,
    referenceData = []
  ) => {
    // go through all data[nodeid].custom.content.key and collect new entry from it
    // also recursively through all nested prescriptions!
    for (let nodeid of Object.keys(presData)) {
      if (nodeid !== nodeidToIgnore) {
        if (presData[nodeid].type === "prescription") {
          // get nested prescription flowcontext, if available, otherwise generate it recursively
          let presHistoryId = presData[nodeid].historyId;
          let response = await retryFetch(
            `${config.API_BASE}/api/prescriptionhistory/withname/${presHistoryId}`
          );
          let prescription = await response.json();

          let innerDiagram = JSON.parse(prescription.diagramJSON);
          let innerPresFlowcontext = innerDiagram.flowcontext;

          if (innerPresFlowcontext) {
            for (let innerVariable of innerPresFlowcontext) {
              if (subPresName)
                innerVariable.description = `${innerVariable.description} (${subPresName})`;
              result.push(innerVariable);
            }
          } else {
            let innerPresData = innerDiagram.data;
            await generateFlowcontextFromData(
              innerPresData,
              result,
              prescription.fiPrescription.name
            );
          }
        } else {
          let customData = presData[nodeid].custom;
          if (customData) {
            if (!Array.isArray(customData)) {
              customData = convertOldCustomToNew(customData);
            }
            for (let field of customData) {
              let newKey = field.content?.key;
              if (newKey) {
                let existingEntry = result.find(
                  (entry) => entry.key === newKey
                );
                if (existingEntry) {
                  // already exists? Increase count
                  existingEntry.count = existingEntry.count + 1;
                } else {
                  // new entry
                  let referenceDescription = referenceData.find(
                    (entry) => entry.key === newKey
                  )?.description;
                  let newFlowContextEntry = {
                    type: field.type.startsWith("text") ? "text" : field.type,
                    key: newKey,
                    description:
                      referenceDescription ??
                      (subPresName ? `${newKey} (${subPresName})` : newKey),
                    count: 1,
                    used: false,
                  };
                  result.push(newFlowContextEntry);
                }
              }
            }
          }
        }
      }
    }
  };

  const setPrescription = async (json) => {
    // console.log("Setting prescription ...");
    const newVersion = convertPrescription(json);
    setPrescriptionLanguage(newVersion.prescriptionLanguage);
    setData(newVersion.data);
    setNodes(newVersion.nodes);
    setEdges(newVersion.edges);

    // generate flowcontext from data
    if (!newVersion.flowcontext) {
      let result = [];
      await generateFlowcontextFromData(newVersion.data, result);
      setFlowContext(result);
    } else {
      setFlowContext(newVersion.flowcontext);
    }

    if (newVersion.contract) {
      setContract(newVersion.contract);
    } else {
      setContract([]);
    }
  };

  const clearReactFlow = useCallback(() => {
    setNodes([]);
  }, [setNodes]);

  useEffect(() => {
    if (!nodes.length) {
      setEdges([]);
      setData({});
    }
  }, [nodes, setEdges]);

  return (
    <MyContext.Provider
      value={{
        nodes,
        setNodes,
        edges,
        setEdges,
        data,
        setData,
        onNodesChange,
        onEdgesChange,
        showModal,
        setShowModal,
        showDataItemMapping,
        setShowDataItemMapping,
        dataItemMapping,
        setDataItemMapping,
        mappingNodeId,
        setMappingNodeId,
        selectedOption,
        setSelectedOption,
        selectedActivity,
        setSelectedActivity,
        showOptionEdit,
        setShowOptionEdit,
        showRemoveActivity,
        setShowRemoveActivity,
        designDirection,
        setDesignDirection,
        labelBeingEdited,
        setLabelBeingEdited,
        toBeSaved,
        setToBeSaved,
        transpose,
        prescriptionLanguage,
        setPrescriptionLanguage,
        globalLanguage,
        setGlobalLanguage,
        showOptionI18N,
        setShowOptionI18N,
        showActivityLabelI18N,
        setShowActivityLabelI18N,
        showActivityEdit,
        setShowActivityEdit,
        setPrescription,
        clearReactFlow,
        flowContext,
        setFlowContext,
        selectableContext,
        setSelectableContext,
        contract,
        setContract,
        generateFlowcontextFromData,
        selectedMapping,
        setSelectedMapping,
        paneWasClicked,
        setPaneWasClicked,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export { MyContextProvider, MyContext };
