import {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useRetryFetch from "../hooks/useRetryFetch";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  addEdge,
  Background,
  ConnectionLineType,
  MarkerType,
  useStoreApi,
  useReactFlow,
  useNodesInitialized,
} from "reactflow";
import Activity from "./reactflow/Activity";
import CustomEdge from "./reactflow/CustomEdge";
import { MyContext } from "./reactflow/MyContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import { directionService } from "../services/directionService";
import loadingImage from "../images/loading.gif";

import "reactflow/dist/style.css";

import "./DesignView.css";
import DesignMenu from "./DesignMenu";
import MessageBox from "./MessageBox";
import { Button } from "./ui/button";
import ComboBox from "./ui/combobox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { config } from "../Environment";

import useAuth from "../hooks/useAuth";
import customhistory from "../history/customhistory";
import EditActivity from "./EditActivity";
import LoggerContext from "../context/LoggerProvider";
import validatePrescription from "./reactflow/validate_prescription";
import { convertOldCustomToNew, LANGUAGES } from "../lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

// for modal version of Preview!
import Preview from "./Preview";

import { toast } from "sonner";
import { ArrowLeft, ArrowRight } from "lucide-react";

const HIGHLIGHT_TYPE = {
  NONE: 0,
  ACTIVITY: 1,
  EDGE: 2,
};

const DesignView = () => {
  const {
    nodes,
    edges,
    data,
    onNodesChange,
    onEdgesChange,
    setNodes,
    setEdges,
    setData,
    showModal,
    setShowModal,
    showDataItemMapping,
    setShowDataItemMapping,
    setDataItemMapping,
    dataItemMapping,
    mappingNodeId,
    setMappingNodeId,
    selectedOption,
    setSelectedOption,
    selectedActivity,
    setSelectedActivity,
    showOptionEdit,
    setShowOptionEdit,
    showRemoveActivity,
    setShowRemoveActivity,
    designDirection,
    setDesignDirection,
    labelBeingEdited,
    setToBeSaved,
    transpose,
    prescriptionLanguage,
    showOptionI18N,
    setShowOptionI18N,
    showActivityEdit,
    setShowActivityEdit,
    setPrescription,
    clearReactFlow,
    flowContext,
    setFlowContext,
    selectableContext,
    setSelectableContext,
    contract,
    setContract,
    generateFlowcontextFromData,
    selectedMapping,
    setSelectedMapping,
    paneWasClicked,
    setPaneWasClicked,
  } = useContext(MyContext);

  const { Logger } = useContext(LoggerContext);

  const unsavedChangesRef = useRef();

  const retryFetch = useRetryFetch();

  const location = useLocation();
  const navigate = useNavigate();
  // background shows that PREVIEW should be opened as modal on top of current page!
  let background =
    location.state &&
    location.state.background &&
    location.state.background.pathname !== "/"
      ? location.state.background
      : null;

  const { auth } = useAuth();

  const { t } = useTranslation();

  const [presName, setPresName] = useState("");
  const [initialPresName, setInitialPresName] = useState("");
  const [presOwner, setPresOwner] = useState("");
  const [currentPrescriptionId, setCurrentPrescriptionId] = useState("");
  const [saveIntent, setSaveIntent] = useState(false);
  const [saveIntentAssignMessage, setSaveIntentAssignMessage] = useState(false);
  const [saveBeforeNewIntent, setSaveBeforeNewIntent] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [showMessageBox, setShowMessageBox] = useState(false);
  const [msgBoxTitle, setMsgBoxTitle] = useState("");
  const [msgBoxButtons, setMsgBoxButtons] = useState({});
  const [msgBoxInputs, setMsgBoxInputs] = useState([]);
  const [msgBoxId, setMsgBoxId] = useState("");
  const [msgBoxMoreJSX, setMsgBoxMoreJSX] = useState(null);

  const [showAddNested, setShowAddNested] = useState(false);
  const [showNewPrescriptionSave, setShowNewPrescriptionSave] = useState(false);
  const [presNameNew, setPresNameNew] = useState("");
  const [showNewPrescription, setShowNewPrescription] = useState(false);
  const [showSavePrescriptionAs, setShowSavePrescriptionAs] = useState(false);
  const [showNotOwnerSaveCopy, setShowNotOwnerSaveCopy] = useState(false);
  const [showLoginRegister, setShowLoginRegister] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [currentOption, setCurrentOption] = useState({});
  const [currentActivity, setCurrentActivity] = useState({});
  const [nodesDueSelecting, setNodesDueSelecting] = useState(false);
  const [nodesDueUnSelecting, setNodesDueUnSelecting] = useState(false);

  const [transposable, setTransposable] = useState(true);

  const [selectedNestable, setSelectedNestable] = useState({});
  const [isComboboxValid, setIsComboboxValid] = useState(false);

  const store = useStoreApi();
  const flow = useReactFlow();
  const nodesInitialized = useNodesInitialized({
    includeHiddenNodes: false,
  });

  const highlightInfoRef = useRef({ type: HIGHLIGHT_TYPE.NONE });

  const nodeTypes = useMemo(() => ({ activity: Activity }), []);
  const edgeTypes = useMemo(
    () => ({
      customedge: CustomEdge,
    }),
    []
  );

  const detectedUnsavedChanges = () => {
    setUnsavedChanges(true);
  };

  const onConnect = useCallback(
    (params) => {
      detectedUnsavedChanges();
      return setEdges((eds) => {
        Logger.debug()("Connect params", params, eds);
        params = {
          ...params,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: "black",
            width: 20,
            height: 20,
          },
          type: "customedge",
        };
        return addEdge(params, eds);
      });
    },
    [setEdges]
  );

  const openMessageBox = (title, id, buttons, inputs, moreJSX) => {
    setMsgBoxTitle(title);
    setMsgBoxId(id);
    setMsgBoxButtons(buttons);
    setMsgBoxInputs(inputs);
    setMsgBoxMoreJSX(moreJSX);
    setShowMessageBox(true);
  };

  const handleRemoveOptionOk = (nodeId, idx) => {
    setData((prevData) => {
      let cloned = { ...prevData };
      cloned[nodeId] = { ...cloned[nodeId] };
      cloned[nodeId].options.splice(idx, 1);
      return cloned;
    });

    // remove edge
    setEdges((prevEdges) => {
      let cloned = [...prevEdges];
      let handleId = `${nodeId}-${idx}`;
      let edgeToRemoveIdx = cloned.findIndex(
        (edge) => edge.sourceHandle === handleId
      );
      cloned.splice(edgeToRemoveIdx, 1);
      return cloned;
    });

    highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
  };

  const getEdgesForNode = (nodeId) => {
    let result = [];
    for (let edge of edges) {
      if (edge.source === nodeId || edge.target === nodeId) {
        result.push({ ...edge });
      }
    }
    return result;
  };

  const updateContract = (newFlowContext) => {
    let newContract = structuredClone(contract);

    for (let i = newContract.length - 1; i >= 0; i--) {
      let contractVariable = newContract[i];
      let foundVariable = newFlowContext.find(
        (entry) => entry.key === contractVariable.value
      );
      if (!foundVariable) {
        newContract.splice(i, 1);
      } else {
        contractVariable.label = foundVariable.description;
      }
    }

    setContract(newContract);
  };

  const handleRemoveActivityOk = async () => {
    let edgesToRemove = getEdgesForNode(highlightInfoRef.current.id);
    let changes = [];
    for (let edge of edgesToRemove) {
      changes.push({ type: "remove", id: edge.id });
    }
    onEdgesChange(changes);

    let idToRemoveFromData = highlightInfoRef.current.id;
    // recreate flow context
    let result = [];
    await generateFlowcontextFromData(data, result, "", idToRemoveFromData);
    setFlowContext(result);
    updateContract(result);

    onNodesChange([{ type: "remove", id: highlightInfoRef.current.id }]);

    highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
  };

  const handleSaveOption = (nodeId, idx) => {
    setData((prevData) => {
      let cloned = { ...prevData[nodeId].options[idx] };
      cloned.text = currentOption.text;
      cloned.value = currentOption.value;
      prevData[nodeId].options[idx] = cloned;
      return prevData;
    });
  };

  const handleLabelTextInput = (e) => {
    setCurrentOption((prevOption) => {
      let newOption = { ...prevOption };
      newOption.text = { ...newOption.text };
      newOption.text[prescriptionLanguage] = e.target.value;
      return newOption;
    });
    setUnsavedChanges(true);
  };

  const addNewActivity = () => {
    const {
      height,
      width,
      transform: [transformX, transformY, zoomLevel],
    } = store.getState();

    Logger.debug()(width, height, transformX, transformY, zoomLevel);

    let newX =
      (Math.floor(Math.random() * (width - 250)) - transformX) / zoomLevel;
    let newY =
      (Math.floor(Math.random() * (height - 100)) - transformY) / zoomLevel;

    let newNodeId = Math.floor(Math.random() * 10000).toString();

    let newNodeType = "default";
    if (!nodes.length) {
      newNodeType = "start";
    }

    setNodes((prevNodes) => {
      let cloned = [...prevNodes];
      cloned.push({
        id: newNodeId,
        position: {
          x: newX,
          y: newY,
        },
        type: "activity",
      });
      return cloned;
    });

    setData((prevData) => {
      let cloned = { ...prevData };
      cloned[newNodeId] = {
        label: {
          en: "",
          de: "",
          he: "",
        },
        type: newNodeType,
        options: [
          {
            text: {
              en: "",
              de: "",
              he: "",
            },
            value: `option${Math.floor(Math.random() * 10000)}`,
            handle: `${newNodeId}-0`,
          },
        ],
      };
      return cloned;
    });

    setUnsavedChanges(true);
  };

  const addNestedPrescription = async (nestedPresObj) => {
    Logger.debug()("Adding nested prescription from", nestedPresObj);

    const {
      height,
      width,
      transform: [transformX, transformY, zoomLevel],
    } = store.getState();
    Logger.debug()(width, height, transformX, transformY, zoomLevel);
    let newX =
      (Math.floor(Math.random() * (width - 250)) - transformX) / zoomLevel;
    let newY =
      (Math.floor(Math.random() * (height - 100)) - transformY) / zoomLevel;
    let newNodeId = Math.floor(Math.random() * 10000).toString();

    // determine options from openExits
    let diagramObj = JSON.parse(nestedPresObj.diagramJSON);
    let exitOptions = [];
    let optIdx = 0;
    for (let openExit of diagramObj.openExits) {
      exitOptions.push({
        text: {
          [prescriptionLanguage]: `${openExit.nodeLabel} [${openExit.optionLabel}]`,
        },
        value: openExit.optionValue || "?????",
        innerHandle: openExit.handleId,
        handle: `${newNodeId}-${optIdx}`,
      });
      optIdx++;
    }

    // if there is no current history id, it means that prescription is dirty and new history entry needs to be created
    let presHistoryId = nestedPresObj.history;
    if (!presHistoryId) {
      Logger.debug()(
        "Need to create a new version of this prescription while nesting it"
      );
      let response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/newversion/${nestedPresObj._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: "{}",
        }
      );
      let responseJSON = await response.json();

      presHistoryId = responseJSON.newHistoryId;
    }

    setNodes((prevNodes) => {
      let cloned = [...prevNodes];
      cloned.push({
        id: newNodeId,
        position: {
          x: newX,
          y: newY,
        },
        type: "activity",
      });
      return cloned;
    });
    setData((prevData) => {
      let cloned = { ...prevData };
      cloned[newNodeId] = {
        label: {
          en: `${nestedPresObj.name}`,
          de: `${nestedPresObj.name}`,
          he: `${nestedPresObj.name}`,
        },
        type: "prescription",
        historyId: presHistoryId,
        dataitemmapping: diagramObj.contract?.length
          ? diagramObj.contract.map((variable) => {
              return {
                key: variable.value,
                description: variable.label,
                type: variable.type || "text",
                mappedToOuter: { key: "", description: "" },
              };
            })
          : [],
        options: exitOptions,
      };
      return cloned;
    });

    // add nested flowcontext to current context
    if (diagramObj.flowcontext) {
      setFlowContext((oldFlowContext) => {
        let newFlowContext = structuredClone(oldFlowContext);
        for (let newEntry of diagramObj.flowcontext) {
          // correct description to include nested prescription name
          newEntry.description = `${newEntry.description} (${nestedPresObj.name})`;
          newFlowContext.push(newEntry);
        }
        return newFlowContext;
      });
    } else {
      let newFlowContext = structuredClone(flowContext);
      await generateFlowcontextFromData(
        diagramObj.data,
        newFlowContext,
        nestedPresObj.name
      );
      setFlowContext(newFlowContext);
    }

    setUnsavedChanges(true);
  };

  useEffect(() => {
    if (
      nodesInitialized &&
      transposable &&
      designDirection !== LANGUAGES[prescriptionLanguage].dir
    ) {
      initiateTranspose();
    }
  }, [nodesInitialized]);

  useEffect(() => {
    if (showOptionEdit && selectedOption.nodeId) {
      Logger.debug()("EDIT required for option", selectedOption);

      let theOption = {
        ...data[selectedOption.nodeId].options[selectedOption.idx],
      };
      Logger.debug()("It's the option", theOption);
      setCurrentOption(theOption);
    }
  }, [showOptionEdit, selectedOption]);

  useEffect(() => {
    if (showActivityEdit && selectedActivity) {
      Logger.debug()("EDIT required for activity", selectedActivity);

      let theActivity = {
        ...data[selectedActivity],
      };
      Logger.debug()("It's the activity", theActivity);
      setCurrentActivity(theActivity);
    }
  }, [showActivityEdit, selectedActivity]);

  const changeHighlightInfo = (id, elementtype, selectIt) => {
    Logger.debug()(
      "Change highlight type",
      id,
      highlightInfoRef.current.id,
      elementtype,
      highlightInfoRef.current.type,
      selectIt
    );
    if (elementtype === HIGHLIGHT_TYPE.NONE) {
      //setHighlightInfo({ type: HIGHLIGHT_TYPE.NONE });
      highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
    } else {
      if (
        !selectIt &&
        elementtype === highlightInfoRef.current.type &&
        id === highlightInfoRef.current.id
      ) {
        // unselect only if type is correct and id matches
        //setHighlightInfo({ type: HIGHLIGHT_TYPE.NONE });
        highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
      } else if (selectIt) {
        //setHighlightInfo({ type: elementtype, id });
        highlightInfoRef.current = { type: elementtype, id };
      }
    }
    Logger.debug()("new ref", highlightInfoRef.current);
  };

  const handleNodesChange = (changes) => {
    Logger.debug()("Nodes changes", changes);
    let resultchanges = [];
    for (let change of changes) {
      // only handle select types
      if (change.type === "select") {
        changeHighlightInfo(
          change.id,
          HIGHLIGHT_TYPE.ACTIVITY,
          change.selected
        );
        if (!change.selected && labelBeingEdited) {
          setToBeSaved(true);
        }
      }
      if (change.type !== "remove") {
        resultchanges.push(change);
      } else {
        // show modal question
        setShowRemoveActivity(true);
        // // check edges to or from node
        // let hasEdges =
        //   edges.filter(
        //     (edge) => edge.source === change.id || edge.target === change.id
        //   ).length > 0;
        // if (!hasEdges) {
        //   resultchanges.push(change);
        //   highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
        // } else {
        //   // show modal question
        //   setShowRemoveActivity(true);
        // }
      }
    }
    onNodesChange(resultchanges);
  };

  const handleEdgesChange = (changes) => {
    Logger.debug()("Edges changes", changes);
    let resultChanges = [];
    for (let change of changes) {
      if (change.type === "select") {
        changeHighlightInfo(change.id, HIGHLIGHT_TYPE.EDGE, change.selected);
        resultChanges.push(change);
      } else if (
        change.type !== "remove" ||
        highlightInfoRef.current.type === HIGHLIGHT_TYPE.EDGE
      ) {
        resultChanges.push(change);
        highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
      }
    }
    onEdgesChange(resultChanges);
  };

  useEffect(() => {
    unsavedChangesRef.current = unsavedChanges;

    let unblock;
    if (unsavedChanges) {
      // Block navigation and register a callback that
      // fires when a navigation attempt is blocked.
      unblock = customhistory.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.
        if (window.confirm(t("question_unsaved_changes_when_leaving"))) {
          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        }
      });
    }

    return () => {
      if (typeof unblock === "function") {
        unblock();
      }
    };
  }, [unsavedChanges]);

  useEffect(() => {
    if (saveIntent) {
      setSaveIntent(false);
      // call save
      callMenu("save");
    }
  }, [saveIntent]);

  useEffect(() => {
    if (saveBeforeNewIntent) {
      // call save
      callMenu("save");

      // new
      sessionStorage.removeItem("prescriptionID");
      setPresName(saveBeforeNewIntent);
      setInitialPresName(saveBeforeNewIntent);

      clearReactFlow();
      setSaveBeforeNewIntent("");
    }
  }, [saveBeforeNewIntent]);

  const removeAbandonedNodeData = (tempNodes, tempData) => {
    let result = {};

    for (let node of tempNodes) {
      result[node.id] = tempData[node.id];
    }

    return result;
  };

  const generateDiagram = () => {
    let cleanedNodes = structuredClone(nodes);
    // let cleanedNodes = [...nodes];
    if (designDirection !== LANGUAGES[prescriptionLanguage].dir) {
      if (cleanedNodes.length)
        Logger.debug()("TRANSPOSE called from generateDiagram");
      cleanedNodes = transpose(cleanedNodes);
    }

    cleanedNodes = removeExtraProperties(cleanedNodes);

    let cleanedData = structuredClone(data);
    cleanedData = removeAbandonedNodeData(cleanedNodes, cleanedData);

    for (let nodeId of Object.keys(cleanedData)) {
      let nodeData = cleanedData[nodeId];
      if (!!nodeData.custom && !Array.isArray(nodeData.custom)) {
        nodeData.custom = convertOldCustomToNew(nodeData.custom);
      }
    }

    let openExits = findOpenExitHandles();

    let diagram = {
      prescriptionLanguage,
      flowcontext: flowContext,
      contract: contract,
      version: 2,
      nodes: cleanedNodes,
      edges,
      data: cleanedData,
      openExits,
    };
    return diagram;
  };

  const saveDraft = () => {
    let diagram = JSON.stringify(generateDiagram());
    sessionStorage.setItem("draftprescription", diagram);
  };

  const removeDraft = () => {
    sessionStorage.removeItem("draftprescription");
  };

  const callMenu = (menuid) => {
    const pseudoEvent = {
      target: {
        dataset: {
          id: menuid,
        },
      },
    };
    onMenuClick(pseudoEvent);
  };

  const handleChange = (nestedPrescription) => {
    Logger.debug()("Changed selected nestable to", nestedPrescription);
    setSelectedNestable(nestedPrescription);
  };

  const onMenuClick = async (e) => {
    let selectedMenu = e.target.dataset.id;

    switch (selectedMenu) {
      case "add":
        addNewActivity();
        break;
      case "addnestedprescription":
        setShowAddNested(true);
        break;
      case "new":
        if (unsavedChanges) {
          setShowNewPrescriptionSave(true);
        } else {
          setShowNewPrescription(true);
        }
        break;
      case "saveas":
        if (!auth.email) {
          toast.error(t("msg_login_required_for_save"), {
            className: "!bg-red-500 !text-white",
          });
          break;
        }
        setShowSavePrescriptionAs(true);
        break;
      case "save":
        if (!auth.email) {
          saveDraft();
          toast.error(t("msg_login_required_for_save"), {
            className: "!bg-red-500 !text-white",
          });
          break;
        }
        if (!presName) {
          callMenu("saveas");
          break;
        }

        if (presOwner && presOwner !== auth.email) {
          setShowNotOwnerSaveCopy(true);
          break;
        }

        let finalMessage = "";

        try {
          let diagram = generateDiagram();

          let validationResult = validatePrescription(JSON.stringify(diagram));
          let isValid =
            validationResult.nodesReachable &&
            validationResult.oneOptionPerNode &&
            validationResult.openExits;

          Logger.debug()("Diagram to be saved is valid?", diagram, isValid);

          let id = sessionStorage.getItem("prescriptionID");

          if (!id) {
            // create new prescription
            let presHistoryId;
            if (sessionStorage.getItem("prescriptionHistoryID")) {
              presHistoryId = sessionStorage.getItem("prescriptionHistoryID");
              sessionStorage.removeItem("prescriptionHistoryID");
            }
            let newPrescription = {
              name: presName,
              diagramJSON: JSON.stringify(diagram),
              dirty: true,
              fiPrescriptionHistory: presHistoryId,
              valid: isValid,
            };
            sessionStorage.removeItem("newPrescriptionName");
            let newResponse = await retryFetch(
              config.API_BASE + "/api/prescriptions/",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(newPrescription),
              }
            );
            if (newResponse.ok) {
              newPrescription = await newResponse.json();
              sessionStorage.setItem("prescriptionID", newPrescription._id);
              finalMessage = t("msg_save_successful");
              setUnsavedChanges(false);
              setCurrentPrescriptionId(newPrescription._id);
            } else {
              finalMessage = t("msg_error_save_prescription");
            }
          } else {
            setCurrentPrescriptionId(id);
            // update existing prescription

            let updateResponse = await retryFetch(
              `${config.API_BASE}/api/prescriptions/${id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                  diagram,
                  valid: isValid,
                }),
              }
            );
            if (updateResponse.ok) {
              finalMessage = t("msg_save_successful");
              setUnsavedChanges(false);
            } else {
              finalMessage = t("msg_error_save_prescription");
            }
          }
        } catch (e) {
          finalMessage = t("msg_error_save_prescription");
        }
        if (saveIntentAssignMessage) {
          setSaveIntentAssignMessage(false);

          if (finalMessage === t("msg_save_successful")) {
            toast.success(t("msg_process_assigned"));
          } else {
            toast.error(t("msg_process_assigned_but_save_failed"), {
              className: "!bg-red-500 !text-white",
            });
          }
        } else {
          toast.success(finalMessage);
        }
        break;
      case "validate":
        let presJSON = JSON.stringify(generateDiagram());

        let validationResult = validatePrescription(presJSON);

        showValidationBox(selectedMenu, "pure", true, validationResult);

        break;
      case "preview":
        let pid = sessionStorage.getItem("prescriptionID");
        if (!pid || unsavedChanges || (!auth.email && !presName)) {
          saveDraft();
        }
        //navigate("/preview");
        // window.open("/preview", "_blank");
        navigate("/design", { state: { background: location } });
        break;
      case "run":
        if (currentPrescriptionId && !unsavedChanges) {
          sessionStorage.removeItem("draftprescription");

          let runPresJSON = JSON.stringify(generateDiagram());

          let runValidationResult = validatePrescription(runPresJSON);

          let isValid =
            runValidationResult.nodesReachable &&
            runValidationResult.oneOptionPerNode &&
            runValidationResult.openExits;

          showValidationBox(selectedMenu, "run", isValid, runValidationResult);
        } else {
          toast.error(t("msg_save_before_run"), {
            className: "!bg-red-500 !text-white",
          });
        }
        break;
      case "share":
        if (!auth.email) {
          toast.error(t("msg_login_required_for_share"), {
            className: "!bg-red-500 !text-white",
          });
          break;
        }
        let presid = sessionStorage.getItem("prescriptionID");
        if (!presid || unsavedChanges) {
          toast.error(t("msg_save_before_share"), {
            className: "!bg-red-500 !text-white",
          });
        } else {
          let sharePresJSON = JSON.stringify(generateDiagram());

          let shareValidationResult = validatePrescription(sharePresJSON);

          let shareIsValid =
            shareValidationResult.nodesReachable &&
            shareValidationResult.oneOptionPerNode &&
            shareValidationResult.openExits;

          showValidationBox(
            selectedMenu,
            "share",
            shareIsValid,
            shareValidationResult
          );
        }
        break;
      case "zoomin":
        flow.zoomIn();
        break;
      case "zoomout":
        flow.zoomOut();
        break;
      case "fittoview":
        flow.fitView();
        break;
      default: {
      }
    }
  };

  const initReactFlow = (unsavedGraph) => {
    sessionStorage.removeItem("processID");
    sessionStorage.removeItem("nextActivity");

    let isRTL = document.body.dir === "rtl";

    Logger.debug()("useEffect[] is running in RTL mode? " + isRTL);

    // load model from database
    const loadPrescription = async () => {
      let restString = "";
      // TODO: Find out why this doesn't work ... leads to data[nodeId] is undefined errors in Activity.jsx
      // if (sessionStorage.getItem("draftprescription")) {
      //   restString = sessionStorage.getItem("draftprescription");
      //   removeDraft();
      //   setCurrentPrescriptionId(sessionStorage.getItem("prescriptionID"));
      //   setPresOwner(auth.email || "");
      //   setPresName("Test 123");
      // } else

      if (sessionStorage.getItem("prescriptionHistoryID")) {
        let historyId = sessionStorage.getItem("prescriptionHistoryID");

        setLoading(true);

        let responseHistory = await retryFetch(
          `${config.API_BASE}/api/fullprescriptionhistory/${historyId}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (responseHistory.ok) {
          let presResponse = await responseHistory.json();

          setPresName(presResponse.fiPrescription?.name);
          setInitialPresName(presResponse.fiPrescription?.name);
          setPresOwner(presResponse.fiPrescription?.fiUser?.email);

          restString = presResponse.diagramJSON;
        }
      } else {
        let id = sessionStorage.getItem("prescriptionID");
        removeDraft();
        if (id) {
          setLoading(true);
          setCurrentPrescriptionId(id);

          let response = await retryFetch(
            `${config.API_BASE}/api/prescriptions/${id}`,
            {
              method: "GET",
              credentials: "include",
            }
          );
          if (response.ok) {
            let pres = await response.json();

            setPresName(pres.name);
            setInitialPresName(pres.name);
            setPresOwner(pres.fiUser?.email);

            restString = pres.diagramJSON;

            Logger.debug()(restString);
          }
        } else {
          setCurrentPrescriptionId("");
        }
      }

      if (restString) {
        await setPrescription(restString);
      }

      setLoading(false);

      // is user not-logged-in and did they just run a process? => Login in or register and then assign user to process
      if (sessionStorage.getItem("runProcessID")) {
        if (!auth.email) {
          setShowLoginRegister(true);
        } else {
          let response = await retryFetch(
            `${config.API_BASE}/api/processes/setuser/${sessionStorage.getItem(
              "runProcessID"
            )}/${auth.email}`,
            {
              method: "PUT",
              credentials: "include",
            }
          );
          if (response.ok) {
            let newName = presName + " copy " + Date.now();
            setPresName(newName);
            setInitialPresName(newName);
            setPresOwner(auth.email);
            sessionStorage.removeItem("prescriptionID");
            // sessionStorage.removeItem("prescriptionHistoryID");
            setSaveIntent(true);
            setSaveIntentAssignMessage(true);
            sessionStorage.removeItem("runProcessID");
          }
        }
      }
    };

    loadPrescription();
  };

  useEffectOnce(() => {
    initReactFlow();

    if (document.body.dir !== LANGUAGES[prescriptionLanguage].dir) {
      Logger.debug()(
        "Prescription direction when starting DesignView is different from global direction ... need to initially transpose?"
      );
    }

    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        Logger.debug()("Direction change detected in DesignView");
        // are there any unsaved changes? Keep them!
        if (unsavedChangesRef.current) saveDraft();

        setDesignDirection(document.body.dir);
        setNodes((prevNodes) => {
          if (prevNodes.length) {
            setTransposable(false);

            Logger.debug()(
              "TRANSPOSE called from direction change in DesignView"
            );
          }
          return transpose(prevNodes);
        });
        setEdges((prevEdges) => {
          let cloned = [...prevEdges];
          for (let i = 0; i < cloned.length; i++) {
            let clonedEdge = { ...cloned[i] };
            cloned[i] = clonedEdge;
          }
          return cloned;
        });
        setNodesDueSelecting(true);
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  // MIGRATE
  // useEffect(() => {
  //   // detect changes on the graph
  //   console.log("graphState was changed, new change handler attached");
  //   let cancelTimeout = null;
  //   if (graphState) {
  //     graphState.on("change", (cell) => {
  //       console.log("graph was changed, change handler is running");
  //       setUnsavedChanges(true);
  //       if (!auth.email) {
  //         if (cancelTimeout) {
  //           console.log("Timeout was cancelled " + cancelTimeout);
  //           clearTimeout(cancelTimeout);
  //           cancelTimeout = null;
  //         }
  //         cancelTimeout = setTimeout(() => {
  //           saveDraft();
  //           console.log("Draft version was saved");
  //         }, 2000);
  //         console.log("New timeout created " + cancelTimeout);
  //       }
  //     });
  //   }
  // }, [graphState]);

  const onButton = async (result) => {
    Logger.debug()("MessageBox button was clicked");
    Logger.debug()(result);
    if (result.btnId === "run") {
      Logger.debug()(
        "Validation successful ... now running",
        currentPrescriptionId,
        unsavedChanges
      );
      // run prescription after validation succeeded
      // create a new process and start it
      let newResponse = await retryFetch(
        config.API_BASE + `/api/processes/${currentPrescriptionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            email: auth.email,
          }),
        }
      );
      let result = await newResponse.json();
      Logger.debug()(result);

      sessionStorage.setItem("prescriptionID", currentPrescriptionId);
      sessionStorage.setItem("processID", result.processId);
      sessionStorage.removeItem("nextActivity");
      // window.open("/preview", "_blank");
      navigate("/design", { state: { background: location } });
    } else if (result.btnId === "share") {
      let presid = sessionStorage.getItem("prescriptionID");
      let response = await retryFetch(
        `${config.API_BASE}/api/deeplinks/${presid}`,
        {
          method: "POST",
        }
      );
      let newDeeplink = await response.json();
      if (window.isSecureContext) {
        navigator.clipboard.writeText(
          `${window.location.origin}/preview?id=${newDeeplink.id}`
        );
        toast.success(t("msg_link_copied_to_clipboard"));
      } else {
        toast.error(t("msg_error_clipboard_insecure"), {
          className: "!bg-red-500 !text-white",
        });
      }
    }
  };

  const removeExtraProperties = (oldNodes) => {
    let cloned = [...oldNodes];
    for (let node of cloned) {
      node.width = undefined;
      node.height = undefined;
      node.selected = undefined;
      node.positionAbsolute = undefined;
      node.dragging = undefined;
      node.position.x = Math.floor(node.position.x);
      node.position.y = Math.floor(node.position.y);
    }
    return cloned;
  };

  const findOpenExitHandles = () => {
    // check all nodes for option handles that are not connected with an edge

    let result = [];
    for (let node of nodes) {
      for (let idx = 0; idx < data[node.id].options.length; idx++) {
        if (!edges.find((edge) => edge.sourceHandle === `${node.id}-${idx}`)) {
          let newOpenExitHandle = {
            nodeLabel: data[node.id].label[prescriptionLanguage],
            optionLabel: data[node.id].options[idx].text[prescriptionLanguage],
            optionValue: data[node.id].options[idx].value,
            handleId: `${node.id}-${idx}`,
          };
          result.push(newOpenExitHandle);
        }
      }
    }
    return result;
  };

  useEffect(() => {
    if (nodesDueSelecting) {
      setTimeout(() => {
        Logger.debug()("useEffect: nodes due selecting!");

        // Workaround: Simulate selecting every node
        let changes = [];
        for (let node of nodes) {
          changes.push({
            id: node.id,
            type: "select",
            selected: true,
          });
        }
        onNodesChange(changes);
        setNodesDueSelecting(false);
        setNodesDueUnSelecting(true);
      }, 10);
    } else if (nodesDueUnSelecting) {
      setTimeout(() => {
        Logger.debug()("useEffect: nodes due unselecting!");

        // Workaround: Simulate unselecting every node
        let changes = [];
        for (let node of nodes) {
          changes.push({
            id: node.id,
            type: "select",
            selected: false,
          });
        }
        onNodesChange(changes);
        setNodesDueUnSelecting(false);

        highlightInfoRef.current = { type: HIGHLIGHT_TYPE.NONE };
      }, 10);
    }
  }, [nodes, nodesDueSelecting, nodesDueUnSelecting]);

  const showValidationBox = (
    selectedMenu,
    modus, // pure, run, share
    isValid,
    validationResult
  ) => {
    openMessageBox(
      t("validation_result"),
      selectedMenu,
      [
        isValid
          ? modus === "pure"
            ? { id: "ok", text: t("ok") }
            : modus === "run"
            ? { id: "run", text: t("run") }
            : { id: "share", text: t("share") }
          : {
              id: "cancel",
              text: t("cancel"),
            },
      ],
      [],
      <div className="msgtext">
        <div>
          {validationResult.nodesReachable ? (
            <FontAwesomeIcon
              icon={solid("check")}
              style={{ color: "lightgreen" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={solid("close")}
              style={{ color: "red", padding: "0 4px 0 2px" }}
            />
          )}{" "}
          {t("every_step_reachable")}
        </div>
        <div>
          {validationResult.oneOptionPerNode ? (
            <FontAwesomeIcon
              icon={solid("check")}
              style={{ color: "lightgreen" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={solid("close")}
              style={{ color: "red", padding: "0 4px 0 2px" }}
            />
          )}{" "}
          {t("every_step_one_option")}
        </div>
        <div>
          {validationResult.openExits ? (
            <FontAwesomeIcon
              icon={solid("check")}
              style={{ color: "lightgreen" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={solid("close")}
              style={{ color: "red", padding: "0 4px 0 2px" }}
            />
          )}{" "}
          {t("open_exits_available")}
        </div>
        {isValid || modus === "pure" ? (
          <></>
        ) : modus === "run" ? (
          <div className="ptop">
            <b>{t("prescription_cannot_run")}</b>
          </div>
        ) : (
          <div className="ptop">
            <b>{t("prescription_cannot_be_shared")}</b>
          </div>
        )}
      </div>
    );
  };

  const initiateTranspose = () => {
    setNodes((prevNodes) => {
      if (prevNodes.length) Logger.debug()("TRANSPOSE from initiateTranspose");
      return transpose(prevNodes);
    });
    setEdges((prevEdges) => {
      let cloned = [...prevEdges];
      for (let i = 0; i < cloned.length; i++) {
        let clonedEdge = { ...cloned[i] };
        cloned[i] = clonedEdge;
      }
      return cloned;
    });
    setNodesDueSelecting(true);
    setTransposable(false);
  };

  const handleMapEntryChange = (mapIdx, val) => {
    setSelectedMapping((oldMapping) => {
      let newMapping = structuredClone(oldMapping);
      newMapping[mapIdx] = val;
      return newMapping;
    });
  };

  const handlePaneClick = () => {
    setPaneWasClicked(true);
  };

  return (
    <div className="flexArea h-full">
      <DesignMenu
        onClick={onMenuClick}
        initialPresName={initialPresName}
        setPresName={setPresName}
        // newPresName={newPresName}
        // setNewPresName={setNewPresName}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      <div className="bg-secondary w-lvw leading-6 ms-10 h-full">
        {transposable &&
        designDirection !== LANGUAGES[prescriptionLanguage].dir ? (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon
              icon={solid("arrows-left-right")}
              onClick={initiateTranspose}
              title={t("transpose")}
            />
          </div>
        ) : (
          <></>
        )}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={handleNodesChange}
          onEdgesChange={handleEdgesChange}
          onNodeDragStart={detectedUnsavedChanges}
          onNodesDelete={detectedUnsavedChanges}
          onEdgeUpdateStart={detectedUnsavedChanges}
          onEdgesDelete={detectedUnsavedChanges}
          onConnect={onConnect}
          onPaneClick={handlePaneClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          dir="ltr"
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
        >
          <Background variant="lines" color="#c9c9c9" />
        </ReactFlow>
        {/* <pre>{JSON.stringify(flowContext, null, 2)}</pre> */}
      </div>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("delete")}</DialogTitle>
          </DialogHeader>
          <p>{t("msg_del_option_has_connections")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setShowModal(false);
                handleRemoveOptionOk(selectedOption.nodeId, selectedOption.idx);
                setSelectedOption({});
                setUnsavedChanges(true);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowModal(false);
                setSelectedOption({});
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showRemoveActivity} onOpenChange={setShowRemoveActivity}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("delete")}</DialogTitle>
          </DialogHeader>
          <p>{t("msg_del_activity_has_connections")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setShowRemoveActivity(false);
                handleRemoveActivityOk();
                setUnsavedChanges(true);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowRemoveActivity(false);
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showOptionEdit} onOpenChange={setShowOptionEdit}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("change_option")}</DialogTitle>
          </DialogHeader>
          <div className="inputdiv">
            <label>
              {t("label_text") +
                " (" +
                t(LANGUAGES[prescriptionLanguage].label) +
                ")"}
            </label>
            <br />
            <input
              type="text"
              className="modalinputtext"
              value={
                currentOption.text
                  ? currentOption.text[prescriptionLanguage]
                  : ""
              }
              onChange={handleLabelTextInput}
              autoFocus
            />
            <FontAwesomeIcon
              icon={solid("globe")}
              style={
                designDirection === "ltr"
                  ? { marginLeft: "10px", cursor: "pointer" }
                  : { marginRight: "10px", cursor: "pointer" }
              }
              onClick={() => setShowOptionI18N(true)}
            />
          </div>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setShowOptionEdit(false);
                handleSaveOption(selectedOption.nodeId, selectedOption.idx);
                setSelectedOption({});
                setUnsavedChanges(true);
              }}
              data-id={"save"}
              key={0}
              variant={`default`}
            >
              {t("save")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowOptionEdit(false);
                setSelectedOption({});
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showOptionI18N} onOpenChange={setShowOptionI18N}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("option_label_translations")}</DialogTitle>
          </DialogHeader>
          {Object.keys(LANGUAGES).map((language) => (
            <div
              className="inputdiv"
              dir={LANGUAGES[language].dir}
              key={language}
            >
              <label htmlFor={language}>{t(LANGUAGES[language].label)}</label>
              <br />
              <input
                type="text"
                className="modalinputtext"
                value={currentOption.text ? currentOption.text[language] : ""}
                onChange={(e) => {
                  setCurrentOption((prevCurrentOption) => {
                    let cloned = { ...prevCurrentOption };
                    cloned.text = { ...cloned.text };
                    cloned.text[language] = e.target.value;
                    return cloned;
                  });
                  setUnsavedChanges(true);
                }}
                id={language}
              />
            </div>
          ))}
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setShowOptionI18N(false);
              }}
              data-id={"ok"}
              key={0}
              variant={`default`}
            >
              {t("ok")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showActivityEdit}
        onOpenChange={() => setShowActivityEdit(!showActivityEdit)}
      >
        <DialogContent className="lg:min-w-[950px] min-w-full">
          <EditActivity
            customData={data[selectedActivity]?.custom}
            onSave={(exported) => {
              setData((oldData) => {
                let newData = { ...oldData };
                if (!newData[selectedActivity]) {
                  newData[selectedActivity] = {};
                }
                newData[selectedActivity].custom = exported;

                // update all variable usages in flowContext
                setFlowContext((oldFlowContext) => {
                  let result = [];
                  for (let oldEntry of oldFlowContext) {
                    let newEntry = { ...oldEntry };
                    let found = false;
                    // go through all interpolation fields and check
                    for (let nodeId of Object.keys(data)) {
                      let node = data[nodeId];
                      if (node.custom) {
                        if (!Array.isArray(node.custom))
                          node.custom = convertOldCustomToNew(node.custom);
                        for (let currentField of node.custom) {
                          if (
                            currentField.type === "interpolation" &&
                            currentField.content.includes(`{${newEntry.key}}`)
                          ) {
                            found = true;
                            break;
                          }
                        }
                      }
                    }
                    newEntry.used = found;
                    result.push(newEntry);
                  }
                  updateContract(result);
                  return result;
                });
                return newData;
              });
              setShowActivityEdit(false);
              setSelectedActivity("");
            }}
            onCancel={() => {
              setShowActivityEdit(false);
              setSelectedActivity("");
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={showDataItemMapping} onOpenChange={setShowDataItemMapping}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("contract_data_item_mapping")}</DialogTitle>
          </DialogHeader>
          <div className="flex">
            <div className="flex-1 font-bold">{t("outer_flow_data")}</div>
            <div className="flex-none mr-3 ml-3">&nbsp;</div>
            <div className="flex-1 font-bold">{t("contract_data")}</div>
          </div>
          {dataItemMapping.map((mapEntry, mapIdx) => (
            <div className="flex">
              <div className="flex-1">
                <Select
                  onValueChange={(val) => handleMapEntryChange(mapIdx, val)}
                  value={selectedMapping[mapIdx]}
                >
                  <SelectTrigger className="max-w-[95%]">
                    <SelectValue placeholder={selectedMapping[mapIdx]} />
                  </SelectTrigger>
                  <SelectContent>
                    {selectableContext
                      .filter((entry) => entry.type === mapEntry.type)
                      .map((variable) => (
                        <SelectItem value={variable.key}>
                          {variable.description}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex-none mr-2 mt-2">
                {designDirection === "ltr" ? <ArrowRight /> : <ArrowLeft />}
              </div>
              <div className="flex-1 border-2 rounded p-1 pl-2">
                {mapEntry.description}
              </div>
            </div>
          ))}
          {/* {JSON.stringify(selectableContext, null, 2)} */}
          {/* {JSON.stringify(selectedMapping, null, 2)} */}
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                // save mapping to data[nodeId].dataitemmapping
                let mappingToStore = structuredClone(
                  data[mappingNodeId].dataitemmapping
                );
                for (let i = 0; i < selectedMapping.length; i++) {
                  let mappedOuterKey = selectedMapping[i];
                  if (mappedOuterKey) {
                    let flowEntry = flowContext.find(
                      (entry) => entry.key === mappedOuterKey
                    );
                    mappingToStore[i].mappedToOuter = {
                      key: flowEntry.key,
                      description: flowEntry.description,
                    };
                  } else {
                  }
                }
                let nodeIdToStore = mappingNodeId;

                setData((oldData) => {
                  let newData = structuredClone(oldData);

                  newData[nodeIdToStore].dataitemmapping = mappingToStore;

                  return newData;
                });

                setShowDataItemMapping(false);
                setDataItemMapping([]);
                setSelectableContext([]);
                setSelectedMapping([]);
                setSelectableContext([]);
                setMappingNodeId("");
              }}
              data-id={"ok"}
              key={0}
              variant={`default`}
            >
              {t("ok")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowDataItemMapping(false);
                setDataItemMapping([]);
                setSelectableContext([]);
                setSelectedMapping([]);
                setSelectableContext([]);
                setMappingNodeId("");
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showAddNested} onOpenChange={setShowAddNested}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("select_prescription_to_add")}</DialogTitle>
          </DialogHeader>
          <ComboBox
            defaultValue=""
            baseURL={`${config.API_BASE}/api/dynamic/${auth.email}`}
            apiProp="prescriptions"
            onChange={handleChange}
            setIsComboboxValid={setIsComboboxValid}
            sortBy="name"
            limit={10}
            labelProp="name"
            valueProp="_id"
            debounceTimerMs={300}
            exclude={currentPrescriptionId}
          />
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                // add nested prescription
                Logger.debug()("Selected prescription is", selectedNestable);
                addNestedPrescription(selectedNestable);
                setShowAddNested(false);
                setIsComboboxValid(false);
              }}
              data-id={"add"}
              key={0}
              variant={`default`}
              disabled={!isComboboxValid}
            >
              {t("add")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowAddNested(false);
                setIsComboboxValid(false);
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showNewPrescriptionSave}
        onOpenChange={setShowNewPrescriptionSave}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("new_prescription")}</DialogTitle>
          </DialogHeader>
          <label htmlFor="newName">{t("name")}</label>
          <input
            className="border-2 rounded pt-1 pl-2 pb-1"
            type="text"
            value={presNameNew}
            onChange={(e) => setPresNameNew(e.target.value)}
            id="newName"
          />
          <p>{t("msg_save_before_new")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setContract([]);
                setFlowContext([]);
                setSaveBeforeNewIntent(presNameNew);
                setUnsavedChanges(false);
                setShowNewPrescriptionSave(false);
                setPresNameNew("");
              }}
              data-id={"save"}
              key={0}
              variant={`default`}
              disabled={!presNameNew}
            >
              {t("save")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                sessionStorage.removeItem("prescriptionID");
                setPresName(presNameNew);
                setInitialPresName(presNameNew);
                clearReactFlow();
                setContract([]);
                setFlowContext([]);
                setUnsavedChanges(false);
                setShowNewPrescriptionSave(false);
                setPresNameNew("");
              }}
              data-id={"dontsave"}
              key={1}
              variant={`outline`}
              disabled={!presNameNew}
            >
              {t("dont_save")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowNewPrescriptionSave(false);
                setPresNameNew("");
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showNewPrescription} onOpenChange={setShowNewPrescription}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("new_prescription")}</DialogTitle>
          </DialogHeader>
          <label htmlFor="newName">{t("name")}</label>
          <input
            className="border-2 rounded pt-1 pl-2 pb-1"
            type="text"
            value={presNameNew}
            onChange={(e) => setPresNameNew(e.target.value)}
            id="newName"
          />
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                sessionStorage.removeItem("prescriptionID");
                setPresName(presNameNew);
                setInitialPresName(presNameNew);
                setContract([]);
                setFlowContext([]);
                clearReactFlow();
                setShowNewPrescription(false);
                setPresNameNew("");
              }}
              data-id={"ok"}
              key={0}
              variant={`default`}
              disabled={!presNameNew}
            >
              {t("ok")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowNewPrescription(false);
                setPresNameNew("");
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showSavePrescriptionAs}
        onOpenChange={setShowSavePrescriptionAs}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("save_prescription_as")}</DialogTitle>
          </DialogHeader>
          <label htmlFor="newName">{t("name")}</label>
          <input
            className="border-2 rounded pt-1 pl-2 pb-1"
            type="text"
            value={presNameNew}
            onChange={(e) => setPresNameNew(e.target.value)}
            id="newName"
          />
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                sessionStorage.removeItem("prescriptionID");
                setPresName(presNameNew);
                setInitialPresName(presNameNew);
                setSaveIntent(true);
                setPresNameNew("");
                setShowSavePrescriptionAs(false);
              }}
              data-id={"ok"}
              key={0}
              variant={`default`}
              disabled={!presNameNew}
            >
              {t("ok")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setPresNameNew("");
                setShowSavePrescriptionAs(false);
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showNotOwnerSaveCopy}
        onOpenChange={setShowNotOwnerSaveCopy}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("question_not_owner_save_copy")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                let newName = presName + " copy " + Date.now();
                setPresName(newName);
                setInitialPresName(newName);
                setPresOwner(auth.email);
                sessionStorage.removeItem("prescriptionID");
                setSaveIntent(true);
                setShowNotOwnerSaveCopy(false);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowNotOwnerSaveCopy(false);
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showLoginRegister} onOpenChange={setShowLoginRegister}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("question_login_register")}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={() => {
                setShowLoginRegister(false);

                // login/register and come back to DesignView afterwards!
                navigate("/login", {
                  state: { from: { pathname: "/design" } },
                });
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowLoginRegister(false);
                sessionStorage.removeItem("runProcessID");
                sessionStorage.removeItem("prescriptionHistoryID");
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {showMessageBox ? (
        <MessageBox
          onButton={onButton}
          buttons={msgBoxButtons}
          text={msgBoxTitle}
          inputs={msgBoxInputs}
          id={msgBoxId}
          moreJSX={msgBoxMoreJSX}
          onClose={() => setShowMessageBox(false)}
        />
      ) : (
        ""
      )}
      {isLoading ? (
        <div className="loadingcenter">
          <img src={loadingImage} alt="" />
        </div>
      ) : (
        ""
      )}
      {background && <Preview />}
    </div>
  );
};

export default DesignView;
