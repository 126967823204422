import { useState, useEffect, useContext } from "react";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import "./Header.css";
import claryNextLogo from "../images/Logo.svg";
import cookies from "js-cookie";

import { config } from "../Environment";
import { WSContext } from "../App";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { distributionService } from "../services/distributionService";
import { directionService } from "../services/directionService";
import MessageBox from "./MessageBox";
import useRetryFetch from "../hooks/useRetryFetch";
import { useLocation } from "react-router-dom";

import LoggerContext from "../context/LoggerProvider";
import PresListContext from "../context/PresListProvider";
import { Button } from "./ui/button";
import { Globe } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
} from "./ui/dropdown-menu";

const availableLanguages = [
  {
    code: "he",
    name: "עברית",
    country_code: "il",
    dir: "rtl",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "de",
    name: "Deutsch",
    country_code: "de",
  },
];

function Header() {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const retryFetch = useRetryFetch();

  const [showMessageBox, setShowMessageBox] = useState(false);
  const [msgBoxTitle, setMsgBoxTitle] = useState("");
  const [msgBoxButtons, setMsgBoxButtons] = useState({});
  const [msgBoxInputs, setMsgBoxInputs] = useState([]);
  const [msgBoxId, setMsgBoxId] = useState("");
  const [msgBoxMoreJSX, setMsgBoxMoreJSX] = useState(null);

  const [chatRequestUser, setChatRequestUser] = useState("");
  const [chatRequestProcessId, setChatRequestProcessId] = useState("");
  const [chatRequestRoomId, setChatRequestRoomId] = useState(0);

  const [currentLanguageCode, setCurrentLanguageCode] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [previousLanguage, setPreviousLanguage] = useState({});

  const { t } = useTranslation();

  const { socket, roomId, setRoomId, globalAbortController } =
    useContext(WSContext);

  const { Logger } = useContext(LoggerContext);

  const {
    setPrescriptions,
    setPublicPrescriptions,
    setProcesses,
    setExpertProcesses,
  } = useContext(PresListContext);

  const openMessageBox = (title, id, buttons, inputs, moreJSX) => {
    setMsgBoxTitle(title);
    setMsgBoxId(id);
    setMsgBoxButtons(buttons);
    setMsgBoxInputs(inputs);
    setMsgBoxMoreJSX(moreJSX);
    setShowMessageBox(true);
  };

  const onButton = async (result) => {
    Logger.debug()("MessageBox button was clicked");
    Logger.debug()(result);

    if (result.btnId === "yes") {
      // chat was accepted, change to ShowFullLog, join room and notify user
      socket.send(
        JSON.stringify({
          command: "acceptchat",
          payload: { email: chatRequestUser, roomId: chatRequestRoomId },
        })
      );

      // collect necessary process log information
      let response = await retryFetch(
        `${config.API_BASE}/api/processes/${chatRequestProcessId}`
      );
      let process = await response.json();

      let stateToPass = {
        processId: chatRequestProcessId,
        presName: process.prescriptionName,
        presHistoryId: process.fiPrescriptionHistory._id,
        autochat: true,
      };
      if (process.fiExpert?.email === auth.email) {
        stateToPass.userEmail = process.fiInitiator?.email;
        navigate("/review", { state: stateToPass });
      } else {
        stateToPass.expertEmail = process.fiExpert?.email;
        navigate("/review", { state: stateToPass });
      }
    } else {
      // chat was refused, send message to requesting user
      socket.send(
        JSON.stringify({
          command: "refusechat",
          payload: {
            email: chatRequestUser,
            roomId: chatRequestRoomId,
          },
        })
      );
    }
  };

  useEffect(() => {
    document.body.dir = currentLanguage?.dir || "ltr";
    Logger.info()(
      "body.dir has been set to " + (currentLanguage?.dir || "ltr")
    );

    // register change of RTL<->LTR
    if (previousLanguage?.dir !== currentLanguage?.dir) {
      Logger.debug()(
        "Script direction change detected, forwarding to design view if necessary"
      );
      directionService.sendDirectionChange();
    }
    setPreviousLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    let newLanguage = availableLanguages.find(
      (l) => l.code === currentLanguageCode
    );

    setCurrentLanguage(newLanguage);
    Logger.debug()("currentLanguage has been set");
  }, [currentLanguageCode]);

  useEffectOnce(() => {
    setCurrentLanguageCode(cookies.get("i18next").split("-")[0] || "en");
    Logger.debug()(
      "currentLanguageCode has been set to " +
        (cookies.get("i18next").split("-")[0] || "en")
    );

    checkCookie();

    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe(async (msg) => {
        Logger.debug()("Header incoming message");

        switch (msg.command) {
          case "requestchat":
            setChatRequestUser(msg.payload.email);
            setChatRequestProcessId(msg.payload.processId);
            setChatRequestRoomId(msg.payload.roomId);

            // get process information
            let response = await retryFetch(
              `${config.API_BASE}/api/processes/${msg.payload.processId}`
            );
            let process = await response.json();

            openMessageBox(
              t("msg_chat_requested", {
                user: msg.payload.email,
              }),
              "requestchat",
              [
                { id: "yes", text: t("yes") },
                { id: "no", text: t("no") },
              ],
              [],
              <>
                <table>
                  <thead>
                    <tr>
                      <th>{t("prescription_name")}</th>
                      <th>{t("status")}</th>
                      <th>{t("last_update")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{process.prescriptionName}</td>
                      <td>{t(process.status)}</td>
                      <td>{showLocale(process.lastUpdated)}</td>
                    </tr>
                  </tbody>
                </table>
                <p></p>
              </>
            );
            break;
        }
      });

    return () => {
      // cleanup subscription
      messageUnsubscribe.unsubscribe();
    };
  }, []);

  const showLocale = (ts) => {
    return new Date(ts).toLocaleString(
      cookies.get("i18next").split("-")[0] || "en"
    );
  };

  const login = () => {
    navigate("/login");
  };

  const logout = async () => {
    // Close room first
    if (roomId) {
      Logger.info()("Message LEAVEROOM has been sent!");
      socket.send(
        JSON.stringify({
          command: "leaveroom",
          payload: undefined,
        })
      );
      socket.send(
        JSON.stringify({
          command: "changeuserstate",
          payload: "online",
        })
      );
      Logger.debug()("HEADER: ROOM ID SET TO 0");
      setRoomId(0);
    }

    // abort with globalAbortController (mainly for loading prescription lists)
    sessionStorage.setItem("semaphore", "false");
    globalAbortController.abort();

    let response = await fetch(config.API_BASE + "/logout", {
      credentials: "include",
    });

    if (!response.ok) alert(t("msg_error_logout"));

    let result = await response.json();
    Logger.debug()(result);
    setAuth({});
    localStorage.removeItem("email");
    localStorage.removeItem("accessToken");

    socket.send(
      JSON.stringify({
        command: "logout",
        payload: undefined,
      })
    );

    // get rid of cache
    setPrescriptions([]);
    setPublicPrescriptions([]);
    setProcesses([]);
    setExpertProcesses([]);

    navigate("/");
  };

  // const register = () => {
  //   navigate("/register");
  // };

  const showPrescriptions = () => {
    navigate("/prescriptionlist");
  };

  const selectLanguage = (code) => {
    i18next.changeLanguage(code);
    setCurrentLanguageCode(code);
  };

  const checkCookie = async () => {
    Logger.debug()("Checking cookie from...", location);
    let response = await fetch(`${config.API_BASE}/checkcookie`, {
      credentials: "include",
    });
    let responseObj = await response.json();
    let jwtState = responseObj.result;
    if (!jwtState) {
      Logger.debug()("Cookie expired. Resetting auth...");
      setAuth({});
      localStorage.removeItem("email");
      localStorage.removeItem("accessToken");
      if (
        location.pathname !== "/preview" ||
        !location.search.startsWith("?id=")
      )
        navigate("/");
    } else {
      Logger.debug()("Cookie still valid.");
    }
  };

  return (
    <>
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-secondary px-2 md:px-6 justify-between">
        <Button
          variant="none"
          size="img"
          className="h-full"
          onClick={showPrescriptions}
          // onClick={
          //   !auth?.email && !auth?.accessToken ? goHome : showPrescriptions
          // }
        >
          <img
            className="h-full w-full"
            src={claryNextLogo}
            alt="ClaryNext logo"
            width="90"
          />
        </Button>
        <div className="flex flex-row items-center">
          {!auth?.email && !auth?.accessToken ? (
            <Button onClick={login}>{t("login")}</Button>
          ) : (
            <Button onClick={logout} variant="outline">
              {t("logout")}
            </Button>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger className="bg-transparent ms-2">
              <Globe />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {availableLanguages.map((lang, idx) => (
                <DropdownMenuItem
                  key={idx}
                  onClick={() => selectLanguage(lang.code)}
                >
                  <span
                    className={`flag-icon mx-2 flag-icon-${lang.country_code}`}
                  />
                  {lang.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
      {showMessageBox ? (
        <MessageBox
          onButton={onButton}
          buttons={msgBoxButtons}
          text={msgBoxTitle}
          inputs={msgBoxInputs}
          id={msgBoxId}
          moreJSX={msgBoxMoreJSX}
          onClose={() => setShowMessageBox(false)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Header;
