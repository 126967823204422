import { useRef, useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AutogrowTextarea from "../ui/autogrowtextarea";
import "./Gauge.css";
import LoggerContext from "../../context/LoggerProvider";

const Gauge = ({ itemkey, content, onChange, defaultValue }) => {
  const canvasRef = useRef(null);

  const { t } = useTranslation();

  const [textAreaLabel, setTextAreaLabel] = useState("");
  const [textAreaVisible, setTextAreaVisible] = useState(false);

  const [value, setValue] = useState({});

  const { Logger } = useContext(LoggerContext);

  const handleCanvasValue = (offsetX, offsetY, xValue, yValue) => {
    let context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    let selectedColor = {};

    let cyan = yValue;
    let yellow = xValue;
    let magenta = 0;
    let kontrast = 0;

    selectedColor.r = Math.floor(255 * (1 - cyan / 100) * (1 - kontrast / 100));
    selectedColor.g = Math.floor(
      255 * (1 - magenta / 100) * (1 - kontrast / 100)
    );
    selectedColor.b = Math.floor(
      255 * (1 - yellow / 100) * (1 - kontrast / 100)
    );

    context.fillStyle = `rgba(${selectedColor.r}, ${selectedColor.g}, ${
      selectedColor.b
    }, ${Math.max(xValue, yValue) / 100})`;
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    context.beginPath();
    context.arc(offsetX, offsetY, 3, 0, 2 * Math.PI, false);
    context.fillStyle = "#5890c3";
    context.fill();

    if ((xValue < 20 && yValue < 20) || xValue > 75 || yValue > 75) {
      setTextAreaVisible(true);
      if (xValue < 20 && yValue < 20) setTextAreaLabel(t("little_progress"));
      else setTextAreaLabel(t("good_progress"));
    } else {
      setTextAreaVisible(false);
    }
  };

  const handleCanvasClick = (e) => {
    if (!!onChange) {
      let rect = canvasRef.current.getBoundingClientRect();

      let offsetX = e.clientX - rect.left;
      let offsetY = e.clientY - rect.top;

      let xValue = Math.floor(offsetX);
      let yValue = Math.floor(100 - offsetY);
      Logger.debug()("CANVAS clicked", xValue, yValue);

      let newValue = { ...value };
      newValue.x = xValue;
      newValue.y = yValue;

      setValue(newValue);
      onChange(content.key, newValue);

      handleCanvasValue(offsetX, offsetY, xValue, yValue);
    } else {
      setValue(defaultValue.x ? defaultValue : {});
    }
  };

  const handleFeedbackChange = (e) => {
    if (!!onChange) {
      let newValue = { ...value };
      newValue.feedback = e.target.value;
      setValue(newValue);
      onChange(content.key, newValue);
    } else {
      setValue(defaultValue.x ? defaultValue : {});
    }
  };

  useEffect(() => {
    if (defaultValue.x) {
      let xValue = defaultValue.x;
      let yValue = defaultValue.y;

      let offsetX = xValue;
      let offsetY = 100 - yValue;

      handleCanvasValue(offsetX, offsetY, xValue, yValue);
    }
    setValue(defaultValue);
    if (onChange) onChange(content.key, defaultValue);
  }, [defaultValue]);

  return (
    <div className="ptop gaugedetail">
      <div className="canvasdiv">
        <canvas
          ref={canvasRef}
          width="100"
          height="100"
          className="gaugecanvas"
          onClick={handleCanvasClick}
        />
      </div>
      {textAreaVisible ? (
        <div className="feedbackdiv">
          <label>{textAreaLabel}</label>
          <br />
          <AutogrowTextarea
            minHeight={60}
            value={value.feedback}
            onChange={handleFeedbackChange}
          />
        </div>
      ) : (
        <div className="feedbackdiv"></div>
      )}
    </div>
  );
};

export default Gauge;
